import React, { useEffect, useState } from 'react';
import '../../utils/css/components/challenges/historiqueList.css';
import modifIcon from '../../assets/icon/border_color.png'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import IconSearch from '../../assets/icon/search.png'
import FileHandler from '../challenges/globalDoc';
import { useGlobalState } from '../../views/GlobalStateContext';


export default function HistoriqueVente({ ventes, type_challenge, timer, challenge, modificationOn }) {
    let navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredVentes, setFilteredVentes] = useState(ventes);
    const [numPage, setNumPage] = useState(1);
    const { urlAPI } = useGlobalState();
    const ventesPerPage = 4;

    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
        paginate(1);
    };

    useEffect(() => {
        const filtered = ventes.filter((ventes) => {
            if (searchTerm === '') {
                return true;
            }
            return ventes.sell_date.toLowerCase().includes(searchTerm);
        });
        setFilteredVentes(filtered);
    }, [searchTerm, ventes]);

    const paginate = (pageNumber) => {
        setNumPage(pageNumber);
    };

    const indexOfLastVentes = numPage * ventesPerPage;
    const indexOfFirstVentes = indexOfLastVentes - ventesPerPage;
    const currentVentes = filteredVentes.slice(indexOfFirstVentes, indexOfLastVentes);

    const handleClicDetails = (id) => {
        if (type_challenge == 0) {
            navigate(`/challenges/challenge_trimestriel_details/update_vente/${id}`, {
                state: {
                    name: challenge.name,
                    limitDate: challenge.limit_date,
                    type: challenge.type_challenge,
                    id: challenge.id,
                    dateEnd: challenge.end_date
                }
            });
        } else if (type_challenge == 1) {
            navigate(`/challenges/challenge_exceptionnel_details/update_vente/${id}`, {
                state: {
                    name: challenge.name,
                    limitDate: challenge.limit_date,
                    type: challenge.type_challenge,
                    id: challenge.id,
                    dateEnd: challenge.end_date
                }
            });
        }
    };

    function base64ToBlob(base64Data) {
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray]);
    }

    const handleDownload = async (item) => {
        try {
            const url = `${urlAPI}/s3/download`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "folderName": "challenges",
                    "eTag": item.document_eTag
                }),
            });
            if (!response.ok) {
                throw new Error('Problème lors de la création du document');
            }

            const data = await response.json();
            const blob = base64ToBlob(data.base64Data.base64Data);
            const downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = data.base64Data.fileName;
            downloadLink.click();
        } catch (error) {
            console.error(error);
        }
    }

    const handleClicViewFile = (fileBase64) => {
        const dataUrl = `data:application/pdf;base64,${fileBase64}`;
        window.open(dataUrl);
    };

    return (
        <>
            <div className="find-historique-searchBar">
                <img className='icon-searchBar' src={IconSearch} />
                <input
                    className='input-find-historique'
                    placeholder="Rechercher par date"
                    value={searchTerm}
                    onChange={handleSearch}
                />
            </div>
            <div className='container-historique-vente-doc'>

                <table className='table-historique-vente'>
                    <thead>
                        <tr>
                            <th className='th-historique'>Date</th>
                            <th className='th-historique'>Total</th>
                            {type_challenge == 0 ? (
                                <></>
                            ) : (
                                <>
                                    <th className='th-historique'>Préparateur</th>
                                    <th className='th-historique'>Pièce jointe</th>
                                    <th className='th-historique'></th>
                                </>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {currentVentes.map((item) => (
                            <tr className='tr-clients-historique' key={item.id_sell}>
                                <td className='td-historique'>{item.sell_date}</td>
                                <td className='td-historique'>{item.gain_sell} €</td>
                                <td className='td-historique'>{item.sell_preparateur}</td>
                                <td className='td-historique'>
                                {modificationOn === true && (
                                    <div className='container-col-modif-ventes pointer'  onClick={() => { handleClicDetails(item.id_sell) }}>
                                        <img src={modifIcon} />
                                        <p><b>Modifier</b></p>
                                    </div>
                                )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <FileHandler onFileAdded={(file) => console.log('File added:', file)} />
            </div>
            <div className='div-bottomTabButtons-historique'>
                <div className="pagination-historique">
                    <button className='button-pagination-historique' onClick={() => paginate(numPage - 1)} disabled={numPage === 1}>
                        {"<<<"}
                    </button>
                    <p>{numPage + " / " + Math.ceil(filteredVentes.length / ventesPerPage)}</p>
                    <button className='button-pagination-historique' onClick={() => paginate(numPage + 1)} disabled={currentVentes.length < ventesPerPage || indexOfLastVentes >= filteredVentes.length}>
                        {">>>"}
                    </button>
                </div>
            </div>
        </>
    );
}