import React, { useEffect, useState } from 'react';
import '../../utils/css/components/challenges/historiqueList.css';
import moreInfo from '../../assets/icon/moreInfo.png';
import Modal from '../general/Modal';
import Graph from '../general/Graph';
import Loader from '../general/LoaderGIF';
import { useGlobalState } from '../../views/GlobalStateContext';

export default function HistoriqueList() {

    
    const userToken = localStorage.getItem('userToken');
    const { urlAPI } = useGlobalState();

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredChallenge, setFilteredChallenge] = useState([]);
    const [numPage, setNumPage] = useState(1);
    const [modalOuvert, setModalOuvert] = useState(false);
    const [dataVentes, setDataVentes] = useState(null);
    const [nameChallenge, setNameChallenge] = useState(null);
    const [uvcTotal, setUvcTotal] = useState(0);
    const [gainTotal, setGainTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [dataHistory, setDataHistory] = useState([]);
    const challengePerPage = 4;

    useEffect(() => {
        fetchHistory();
    }, []);

    const fetchHistory = async () => {
        const url = `${urlAPI}/challenge/getHistoryChallenge`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${userToken}`,
                },
            });
            if (!response.ok) {
                throw new Error('Problème lors de la récupération des données challenges');
            }
            let data = await response.json();
            setDataHistory(data);
            setFilteredChallenge(data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
    };

    useEffect(() => {
        const filtered = dataHistory.filter(challenge =>
            searchTerm === '' || challenge.name.toLowerCase().includes(searchTerm)
        );
        setFilteredChallenge(filtered);
    }, [searchTerm, dataHistory]);

    const toggleModal = () => {
        setModalOuvert(!modalOuvert);
    };

    // Calcul de la pagination
    const indexOfLastChallenge = numPage * challengePerPage;
    const indexOfFirstChallenge = indexOfLastChallenge - challengePerPage;
    const currentChallenge = filteredChallenge.slice(indexOfFirstChallenge, indexOfLastChallenge);


    // Calcul de pagination total
    const totalPages = Math.ceil(filteredChallenge.length / challengePerPage);

    return (
        <>
            <input
                className='input-find-historique'
                placeholder='Rechercher'
                value={searchTerm}
                onChange={handleSearch}
            />
            <table className='table-historique'>
                {/* Table header */}
                <thead>
                    <tr>
                        <th className='th-historique'>Nom</th>
                        <th className='th-historique'>Date</th>
                        <th className='th-historique'>Total</th>
                        <th className='th-historique'></th>
                    </tr>
                </thead>
                {/* Table body */}
                <tbody>
                    {currentChallenge.map((item) => (
                        <tr className='tr-clients-historique' key={item.id}>
                            <td className='td-historique'>{item.name}</td>
                            <td className='td-historique'>{item.start_date}</td>
                            <td className='td-historique'>{item.gain} €</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className='div-bottomTabButtons-historique'>
                <div className="pagination-historique">
                    <button className='button-pagination-historique' onClick={() => setNumPage(Math.max(1, numPage - 1))} disabled={numPage === 1}>
                        {"<<<"}
                    </button>
                    <p>{`${numPage} / ${totalPages}`}</p>
                    <button className='button-pagination-historique' onClick={() => setNumPage(Math.min(totalPages, numPage + 1))} disabled={numPage >= totalPages}>
                        {">>>"}
                    </button>
                </div>
            </div>
            {modalOuvert && (
                <Modal isOpen={modalOuvert} onClose={toggleModal} width="600px">
                    <div className='container-header-graph'>
                        <h2 className='subtitle-challenges'>Évolution des gains - {nameChallenge}</h2>
                        <button className='button-modal-second' onClick={toggleModal}>X</button>
                    </div>
                    <Graph data={dataVentes} />
                    <div className='container-footer-graph'>
                        <p className='text-footer-graph'><b>UVC Vendues :</b> {uvcTotal}</p>
                        <p className='text-footer-graph'><b>Gains en euros :</b> {gainTotal} €</p>
                    </div>
                </Modal>
            )}
        </>
    );
}
