import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../utils/css/views/profil.css';
import Sidebar from '../components/general/Sidebar';
import Header from '../components/general/Header';
import updateIcon from '../assets/icon/update_icon.png';
import Modal from '../components/general/Modal';
import { useGlobalState } from '../views/GlobalStateContext';

function Profil() {

    const navigate = useNavigate();
    const userToken = localStorage.getItem('userToken');

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState('*******');
    const [codeClient, setCodeClient] = useState();
    const [pharmacy, setPharmacy] = useState();
    const [user, setUser] = useState()
    const { urlAPI } = useGlobalState();
    const [errorMessage, setErrorMessage] = useState();

    const [modalOuvert, setModalOuvert] = useState(false);

    function padNumberWithZeros(number, targetLength = 7) {
        const numberAsString = number.toString();
        const paddingSize = Math.max(targetLength - numberAsString.length, 0);
        const paddedNumber = '0'.repeat(paddingSize) + numberAsString;
        return paddedNumber;
    }

    useEffect(() => {
        fetchUserDetails();
    }, []);

    const fetchUserDetails = async () => {
        const url = `${urlAPI}/user/getUserWithToken`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${userToken}`,
                },
            });
            if (!response.ok) {
                throw new Error('Problème lors de la récupération des données de l\'utilisateur');
            }
            const data = await response.json();
            setUser(data.content);
            setPharmacy(data.pharmacy);
        } catch (error) {
            console.error('Erreur lors de la récupération des détails de l’utilisateur:', error);
        }
    };

    const handleMenuClick = (path) => {
        navigate(path);
    };

    const toggleModal = () => {
        setModalOuvert(!modalOuvert);
    };

    const toggleModalDelete = () => {
        setModalOuvert(!modalOuvert);
        fetch(urlAPI + "/user/deleteUser", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${userToken}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.message == "Utilisateur supprimé avec succès.") {
                    navigate('/profil')
                } else {
                    setErrorMessage(data.message)
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const handleCreate = () => {
        setModalOuvert(true);
    };

    return (
        <div className='container-profil'>
            <Sidebar />
            <div className='container-all-data-profil'>
                <Header title={"Mon profil"} bold={"profil"} />
                <div className='container-form-profil'>
                    <form className='form-profil'>
                        <div className='container-label-input-first'>
                            <label className="label-input required">Nom de la pharmacie</label>
                            <input
                                type="text"
                                className="input"
                                id="name"
                                value={pharmacy ? pharmacy : ''}
                                onChange={(e) => setName(e.target.value)}
                                required
                                disabled
                            />
                        </div>
                        <div className='container-label-input' >
                            <label className="label-input ">Email</label>
                            <div className='container-input-img'>
                                <img className='img-modif-profil pointer' src={updateIcon} onClick={() => handleMenuClick('/profil/email')} />
                                <input
                                    type="text"
                                    className="input"
                                    id="email"
                                    value={user ? user.email : ''}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    disabled
                                />
                            </div>
                        </div>
                        <div className='container-label-input'>
                            <label className="label-input ">Code client</label>
                            <input
                                type="text"
                                className="input"
                                value={user ? padNumberWithZeros(user.customerCode) : ''}
                                onChange={(e) => setCodeClient(e.target.value)}
                                required
                                disabled
                            />
                        </div>
                        <div className='container-label-input'>
                            <label className="label-input ">Mot de passe</label>
                            <div className='container-input-img'>
                                <img src={updateIcon} className='img-modif-profil pointer' onClick={() => handleMenuClick('/profil/password')} />
                                <input
                                    type="password"
                                    className="input"
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    disabled
                                />
                            </div>
                        </div>
                        <div onClick={handleCreate} className='container-btn'>
                            <p className='text-inscription'>Supprimer mon compte</p>
                        </div>
                    </form>
                </div>
            </div>
            {modalOuvert && (
                <Modal isOpen={modalOuvert} onClose={toggleModal}>
                    <p className='text-modal'>Vous êtes sur le point de suprimer DEFINITIVEMENT votre compte, assurez vous de ne pas être le compte principal afin de ne pas perdre toutes les données</p>

                    <div className='container-button-modal'>
                        <button className='button-modal-second' onClick={toggleModal}>Annuler</button>
                        <button className='button-modal' onClick={toggleModalDelete}>Valider</button>
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default Profil;