import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../utils/css/components/general/sidebar.css';
import logo from '../../assets/img/logo-prescription-nature-HD 1.png';
import iconDeco from '../../assets/icon/logout.png';
import iconHelp from '../../assets/icon/help.png'
import Modal from '../general/Modal'
import { useGlobalState } from '../../views/GlobalStateContext';

function Sidebar(props) {
    const navigate = useNavigate();

    const [modalOuvert, setModalOuvert] = useState(false);
    const { urlAPI } = useGlobalState();
    const userToken = localStorage.getItem('userToken');

    const [isBeneficiary, setIsBeneficiary] = useState(true)

    useEffect(() => {
        fetchUserDetails();
    }, []);

    const fetchUserDetails = async () => {
        const url = `${urlAPI}/user/getUserWithToken`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${userToken}`,
                },
            });
            if (!response.ok) {
                throw new Error('Problème lors de la récupération des données de l\'utilisateur');
            }
            const data = await response.json();
            setIsBeneficiary(data.content.isBeneficiary);
        } catch (error) {
            console.error('Erreur lors de la récupération des détails de l’utilisateur:', error);
        }
    };

    const menu = [
        { name: 'Tableau de bord', path: '/tableau_de_bord' },
        { name: 'Challenges', path: '/challenges' },
        { name: 'Formations', path: '/formations' },
        { name: 'Documents', path: '/documents' },
    ];
    
    if (!isBeneficiary) {
        menu.splice(2, 0, { name: 'Bénéficiaires', path: '/beneficiaires' });
    }

    const handleMenuClick = (path) => {
        navigate(path);
    };

    const toggleModal = () => {
        setModalOuvert(!modalOuvert);
    };

    const handleProducts = () => {
        setModalOuvert(true);
    };

    const handleDisconnect = () => {
        localStorage.removeItem('userToken');
        navigate('/')
    };

    return (
        <div className='container-sidebar'>
            <img className="prescription-nature-logo" src={logo} alt="logo prescription nature" />
            {menu.map((item, index) => (
                props.currentPage === item.name
                    ? <p className="label-sidebar pointer current" key={index} onClick={() => handleMenuClick(item.path)}>{item.name}</p>
                    : <p className="label-sidebar pointer" key={index} onClick={() => handleMenuClick(item.path)}>{item.name}</p>
            ))}
            <div onClick={() => handleMenuClick('/FAQ')} className='container-help-sidebar pointer'>
                <img className='img-need-help' src={iconHelp}/>
                <p className='text-help-sidebar'>J'ai besoin d'aide</p>
            </div>
            <div className='footer-sidebar pointer' onClick={handleProducts}>
                <img className='logout-img-sidebar' src={iconDeco} alt="Déconnexion" />
                <p className='logout-sidebar'>Déconnexion</p>
            </div>
            {modalOuvert && (
                <Modal isOpen={modalOuvert} onClose={toggleModal} width={1}>
                    <p className='text-modal'>Êtes-vous sûr de vouloir vous déconnecter ?</p>
                        <div className='container-button-modal'>
                            <button className='button-modal-second' onClick={toggleModal}>Annuler</button>
                            <button className='button-modal-deco' onClick={handleDisconnect}>Se déconnecter</button>
                        </div>
                </Modal>
            )}
        </div>
    );
}

export default Sidebar;
