import React, { useState, useEffect } from 'react';
import '../utils/css/views/documents.css';
import Sidebar from '../components/general/Sidebar';
import Header from '../components/general/Header';
import SearchBar from '../components/general/SearchBar';
import CollapsibleComponent from '../components/documents/CollapsibleComponent';
import Loader from '../components/general/LoaderGIF'; // Assurez-vous que le chemin est correct
import { useGlobalState } from '../views/GlobalStateContext';

function Documents() {
    const [searchTerm, setSearchTerm] = useState('');
    const { urlAPI } = useGlobalState();
    const [data, setData] = useState([]); // Initialisez data avec un tableau vide
    const [isLoading, setIsLoading] = useState(true); // Ajoutez un état pour gérer l'affichage du loader

    const userToken = localStorage.getItem('userToken');

    const handleSearch = (searchValue) => {
        setSearchTerm(searchValue);
    };

    useEffect(() => {
        fetchAllDocuments();
    }, []);

    const fetchAllDocuments = async () => {
        const url = `${urlAPI}/document/getAllDocuments`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${userToken}`,
                },
            });
            if (!response.ok) {
                throw new Error('Problème lors de la récupération des données document');
            }
            const data = await response.json();
            setData(data);
        } catch (error) {
            console.error('Erreur lors de la récupération des détails de l’utilisateur:', error);
        } finally {
            setIsLoading(false); // Mettez à jour l'état pour arrêter l'affichage du loader
        }
    };

    return (
        <div className='container-documents'>
            <Sidebar currentPage={"Documents"} />
            <div className='container-all-data-documents'>
                <Header title={"Mes documents"} bold={"documents"} />
                <div className='container-without-header-document'>
                    <SearchBar onSearch={handleSearch} />
                    {isLoading ? (
                        <Loader /> // Affichez le loader pendant le chargement des données
                    ) : (
                        <CollapsibleComponent
                            title="Challenges"
                            items={data}
                            searchTerm={searchTerm}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Documents;
