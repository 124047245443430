import React, { useState } from 'react';
import { useGlobalState } from '../views/GlobalStateContext';
import Sidebar from '../components/general/Sidebar';
import Header from '../components/general/Header';
import challengeFaq from '../assets/img/faq/faq-challenge.png';
import updateIcon from '../assets/icon/update_icon.png';
import deleteIcon from '../assets/icon/delete.png';
import '../utils/css/views/faq.css';

function Faq() {

    const { emailPN, telPN } = useGlobalState();

    const data = [
        { type: 'all', question: 'Comment participer aux challenges ?', reponse:(
            <>
            <p> A l’ouverture d’un challenge vous êtes d’office considéré comme participant.Vous n’avez donc aucune autre manipulation à faire.</p>
               <p> Si vous ne souhaitez pas participer à un challenge, il vous suffit de ne pas rajouter de ventes ni de justificatifs. </p>
               <p>Ainsi, vous serez considéré comme non participant.</p>
            </>
        ) 
    },
        { type: 'all', question: 'J’ai oublié mon mot de passe que dois-je faire ?',  reponse: (
            <>
                <p>1. Sur la page de connexion cliquez sur « mot de passe oublié ? ».</p>
                <p>2. Entrez votre e-mail un code vous sera envoyé sur l’adresse mail renseignée. Une fois le code récupéré, renseignez-le dans le champ prévu à cet effet.</p>
                <p>3. Renseignez le nouveau mot de passe choisi puis validez.</p>
            </>
        ) 
    },
        { type: 'all', 
        question: 'Comment ajouter un bénéficiaire ?',
        reponse: (
            <>
                <p>1. Rendez-vous sur la page « Bénéficiaires ».</p>
                <p>2. Appuyez sur le bouton « ajouter un bénéficiaire ».</p>
                <p>3. Renseignez le nom du bénéficiaire ainsi que son adresse e-mail. Une invitation lui sera envoyée sur sa boîte mail.</p>
                <p>4. Une fois que la personne ajoutée aura acceptée l’invitation par mail, il apparaîtra dans la liste des bénéficiaires.</p>
            </>
        )
    },
        { type: 'challenge',
         question: 'Quelle est la différence entre un challenge exceptionnel et un challenge trimestriel ?',
        reponse: (
            <>
                <p>Le challenge trimestriel comporte 4 trimestres d’une durée de 3 mois. Tous les produits prescription nature peuvent être vendus durant les challenges trimestriels et il n’y a pas d’objectif de vente défini.<br />Les challenges exceptionnels ont une durée variable d’1 à 3 mois. Ils concernent des produits ou des gammes en particulier et ont des objectifs de vente définis.</p>
            </>
        ) 
    },
        { type: 'challenge', 
        question: 'A quelle fréquence apparaissent les challenges exceptionnels ?',
        reponse: 'Une réponse sera bientôt disponible.' },

        { type: 'challenge',
         question: 'Comment participer aux challenges ?',
        reponse: (
            <>
            <p> A l’ouverture d’un challenge vous êtes d’office considéré comme participant.Vous n’avez donc aucune autre manipulation à faire.</p>
               <p> Si vous ne souhaitez pas participer à un challenge, il vous suffit de ne pas rajouter de ventes ni de justificatifs. </p>
               <p>Ainsi, vous serez considéré comme non participant.</p>
            </>
        )
    },
      
        { type: 'challenge',
         question: 'Comment déclarer mes ventes ?',
         reponse: (
            <>
                <p>1. Accédez à l’onglet « challenges ». Il est accessible de différentes façons : via le menu ou via le tableau de bord.</p>
                <img src={challengeFaq} alt="Accès à l'onglet challenge"  className="challengefaq-img"/>
                <p>2. Si vous avez cliqué depuis le menu, cliquez sur « j’ajoute mes ventes » pour accéder à la page d’ajout des ventes. Si vous avez cliqué sur « voir le détail », vous retombez directement sur cette page.</p>
                <p>3. Sur cette page, vous avez un tableau contenant la liste des produits possédés par votre pharmacie. Il vous suffit de rentrer la quantité de produits vendus sur la ligne correspondante.</p>
            </>
        ) 
    },
        { type: 'challenge',
         question: 'Puis-je participer à plusieurs challenges à la fois ?', 
         reponse: 'Une réponse sera bientôt disponible.' },

        { type: 'challenge',
         question: 'Comment je reçois mes gains ?',
          reponse: 'Une réponse sera bientôt disponible.' },

        { type: 'account',
         question: 'J’ai oublié mon mot de passe, que dois-je faire ?',
        reponse: (
            <>
            <p>1. Sur la page de connexion, cliquez sur « mot de passe oublié ? ». </p>
            <p>2. Entrez votre e-mail, un code vous sera envoyé sur l’adresse mail renseignée. Une fois le code récupéré, renseignez le dans le champ prévu à cet effet. </p>
            <p>3. Renseignez le nouveau mot de passe choisi, puis validez. </p>
            </>
        )
    },
        { type: 'account', 
        question: 'Je n’ai plus accès à l’adresse mail associée à mon compte',
        reponse: (
            <>
            <p> Contactez notre service client au <span class="bold-text">{telPN}</span> ou par mail sur <span class="bold-text">{emailPN}</span>.</p>
            <p>Notre équipe se chargera de modifier vos informations afin que vous puissiez avoir à nouveau accès à votre espace.</p>
            </>
        )
    },
        { type: 'account',
         question: 'Je voudrais modifier mon mot de passe',
         reponse: (
            <>
            <p>1. Accédez à la page « mon profil ». </p>
            <p>2. Appuyez sur le pictogramme <img src={updateIcon} alt="icon modifier"  className="iconFaq"/> à côté du champ « mot de passe ». </p>
            <p>3. Rentrez votre mot de passe actuel dans le premier champ. </p>
            <p>4. Dans le deuxième champ, rentrez votre nouveau de passe. Il doit respecter les contraintes de sécurité indiquées en dessous du champ afin d’être validé. </p>
            <p>5. Dans le dernier champ, retapez le nouveau mot de passe choisi, puis appuyez sur le bouton « confirmer ». </p>
            </>
        )
    },

        { type: 'account', 
        question: 'Je voudrais modifier mon adresse e-mail',
        reponse: (
            <>
            <p>1. Accédez à la page « mon profil ». </p>
            <p>2. Appuyez sur le pictogramme <img src={updateIcon} alt="icon modifier" className="iconFaq" /> à côté du champ « adresse e-mail  ». </p>
            <p>3. Rentrez votre adresse e-mail actuelle dans le premier champ. </p>
            <p>4. Dans le deuxième champ, rentrez votre nouvelle adresse e-mail, puis dans le dernier champ, retapez la nouvelle adresse e-mail choisie. Appuyez sur le bouton « confirmer ». </p>
            </>
        )
    },

        { type: 'account',
         question: 'Je voudrais supprimer mon compte',
         reponse: (
            <>
            <p>1. Accédez à la page « mon profil ». </p>
            <p>2. Appuyez sur « Supprimer mon compte » en bas de la page. </p>
            <p>3. Un e-mail de confirmation vous sera envoyé sur l’adresse e-mail liée à votre compte. </p>
            <p>Si vous avez changé d’avis, vous disposez d’<span class="bold-text"> 1</span> semaine pour vous rétracter. </p>
            </>
        )
    },

        { type: 'beneficiaire',
         question: 'Comment ajouter un bénéficiaire ?',
          reponse: (
            <>
            <p>1. Rendez-vous sur la page « Bénéficiaires ».</p>
            <p>2. Appuyez sur le bouton « ajouter un bénéficiaire ».</p>
            <p>3. Renseignez le nom du bénéficiaire ainsi que son adresse e-mail. Une invitation lui sera envoyée sur sa boîte mail.</p>
            <p>4. Une fois que la personne ajoutée aura acceptée l’invitation par mail, il apparaîtra dans la liste des bénéficiaires.</p>
            </>
        )
    },
        { type: 'beneficiaire',
         question: 'Comment supprimer un bénéficiaire ?',
         reponse: (
            <>
            <p>1. Rendez-vous sur la page « Bénéficiaires ». </p>
            <p>2. Appuyez sur  <img src={updateIcon} alt="icon modifier" className="iconFaq" />. </p>
            <p>3. Vous verrez apparaître à coté des noms de vos bénéficiaires un bouton  « <img src={deleteIcon} alt="icon corbeille" className="iconFaq" /> supprimer ». Appuyez sur ce bouton afin de supprimer le bénéficiaire souhaité. Un message de confirmation apparaîtra, appuyez sur confirmer. </p>
            <p>4. Une fois que vous avez appuyé sur confirmer, l’utilisateur est instantanément supprimé. </p>
            </>
        )
    },


        { type: 'beneficiaire',
         question: 'Comment modifier un bénéficiaire ?', 
         reponse: (
            <>
            <p>1. Rendez-vous sur la page « Bénéficiaires ».</p>
            <p>2. Appuyez sur  « <img src={updateIcon} alt="icon modifier" className="iconFaq" /> modifier ».</p>
            <p>3. Cliquez sur le nom ou l’adresse e-mail, en fonction de l’information que vous souhaitez modifier.</p>
            </>
        )
    },
    ];

    const item = [
        { label: "Questions fréquentes", type: 'all' },
        { label: "Les challenges", type: 'challenge' },
        { label: "Mon compte", type: 'account' },
        { label: "Les bénéficiaires", type: 'beneficiaire' }
    ]

    const [active, setActive] = useState('all');
    const [selectedQuestion, setSelectedQuestion] = useState(null);

    const handleClickActive = (type) => {
        setActive(type);
        setSelectedQuestion(null);
    };

    const handleClickQuestion = (index) => {
        setSelectedQuestion(prevState => prevState === index ? null : index);
    };

    return (
        <div className='container-faq'>
            <Sidebar />
            <div className='container-all-data-faq'>
                <Header title={"Centre d'aide"} bold={"Centre d'aide"} />
                <div className='container-content-help'>
                    <div className='container-menu-help'>
                        {
                            item.map((item, index) => (
                                <div key={index} className='container-one-label-faq' onClick={() => handleClickActive(item.type)}>
                                    <p className='label-menu-faq'>{item.label}</p>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <mask id="mask0_980_425" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                                <rect width="24" height="24" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_980_425)">
                                                <path d="M12.6 12L8 7.4L9.4 6L15.4 12L9.4 18L8 16.6L12.6 12Z" fill="#1B1C3C" />
                                            </g>
                                        </svg>
                                </div>

                            ))
                        }
                    </div>
                    <div className='container-question-faq'>
                        <div className='container-all-question-faq'>
                            <div className='container-all-question-faq-title'>
                            <p className='title-faq'>Comment pouvons-nous vous aider ?</p>
                            </div>
                            <div className='container-all-question'>

                        {data.filter(item => item.type === active).map((item, index) => (
                            <>
                                <div key={index} className='item-question-faq' onClick={() => handleClickQuestion(index)}>
                                    <p className='text-menu-faq'>{item.question}</p>
                                    <div className={`icon-faq ${selectedQuestion === index ? 'open' : ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ transform: `${selectedQuestion === index ? 'rotate(90deg)' : 'rotate(0deg)'}` }}>
                                            <mask id="mask0_844_504" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                                <rect x="24" width="24" height="24" transform="rotate(90 24 0)" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_844_504)">
                                                <path d="M12 12.6L16.6 8L18 9.4L12 15.4L6 9.4L7.4 8L12 12.6Z" fill="#1C1B1F" />
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                {selectedQuestion === index && (
                                    <div className='text-reponse-faq'>{item.reponse}</div>
                                )}
                            </>
                        ))}
                        </div>
                        </div>
                        <div className='container-footer-faq'>
                            <p className='text-faq bold'>Vous ne trouvez pas la réponse à votre question</p>
                            <p className='text-faq'>Nous en sommes désolés. Vous pouvez contacter le laboratoire au <br /> <span class="bold-text">{telPN}</span> ou par mail sur <span class="bold-text">{emailPN}</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faq;