import React, { useState, useEffect } from 'react';
import '../../utils/css/components/challenges/listingVentes.css';

const ListingVentes = ({ columns, data, onGetData }) => {
    const [ventesData, setVentesData] = useState(data.map(item => ({
        ...item,
        // Assurez-vous que 'Valeur totale' est correctement initialisé
        'Gain total': parseFloat(item['Quantité']) * parseFloat(item['Gain'])
    })));
    const [totalQuantite, setTotalQuantite] = useState(0);
    const [totalValeur, setTotalValeur] = useState(0);

    useEffect(() => {
        let totalQuantite = 0;
        let totalValeur = 0;

        ventesData.forEach(item => {
            totalQuantite += parseFloat(item['Quantité']);
            totalValeur += parseFloat(item['Gain total']);
        });

        setTotalQuantite(totalQuantite);
        setTotalValeur(totalValeur);
    }, [ventesData]);

    const handleQuantityChange = (index, newValue) => {
        let newValueAsNumber = parseFloat(newValue) || 0;

        const updatedData = ventesData.map((item, itemIndex) => {
            if (index === itemIndex) {
                const updatedItem = {
                    ...item,
                    'Quantité': newValueAsNumber,
                    // Mettez à jour 'Valeur totale' en fonction de la nouvelle quantité et la 'Valeur unitaire'
                    'Gain total': newValueAsNumber * parseFloat(item['Gain'])
                };
                return updatedItem;
            }
            return item;
        });

        setVentesData(updatedData);
    };

    useEffect(() => {
        onGetData(ventesData);
    }, [ventesData, onGetData]);

    return (
        <div className='container-tab-ventes'>
            <div className='table-header'>
                <table>
                    <thead>
                        <tr>
                            {columns.map((column, columnIndex) => (
                                <th className='title-listingElement' key={columnIndex}>
                                    {column}
                                </th>
                            ))}
                        </tr>
                    </thead>
                </table>
            </div>
            <div className='table-body'>
                <table>
                    <tbody>
                        {ventesData.map((item, index) => (
                            <tr key={index}>
                                {columns.map((column, columnIndex) => (
                                    <td className={'item-listingElement'} key={columnIndex}>
                                        {columnIndex === 1 ? (

                                        <input
                                            className="custom-input"
                                            type="number"
                                            min="0"
                                            value={item['Quantité'].toString()}
                                            onChange={(e) => {
                                                // Assurez-vous que la valeur n'est pas négative
                                                const newValue = Math.max(0, parseFloat(e.target.value));
                                                handleQuantityChange(index, isNaN(newValue) ? '' : newValue.toString());
                                            }}
                                        />
                                        
                                        ) : column === 'Gain' || column === 'Gain total' || column === 'Prix HT' ? (
                                            `${item[column]} €`
                                        ) : (
                                            item[column]
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className='table-header'>
                <table>
                    <thead>
                        <tr>
                            <th className='title-totaux'></th>
                            <th className='title-totaux'>
                                <p>TOTAL : <span>{totalQuantite}</span></p>
                            </th>
                            <th className='title-totaux'></th>
                            <th className='title-totaux'>
                                <p>TOTAL : <span>{totalValeur.toFixed(2)} €</span></p>
                            </th>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
    );
};

export default ListingVentes;
