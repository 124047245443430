import React from 'react';
import '../../utils/css/components/general/circleProgressBar.css'

const CircleProgressBar = ({ percentage, circleWidth }) => {
    const radius = circleWidth / 2.9;
    const dashArray = radius * Math.PI * 2;
    let dashOffset;

    // Si le pourcentage est supérieur à 100, le remplir comme à 100%
    if (percentage > 100) {
        dashOffset = 0;
    } else {
        dashOffset = dashArray - (dashArray * percentage) / 100;
    }

    const strokeWidth = circleWidth / 15 + "px";
    return (
        <div>
            <svg
                width={circleWidth}
                height={circleWidth}
                viewBox={`0 0 ${circleWidth} ${circleWidth}`}
                className='svg-circle'
            >
                <circle
                    cx={circleWidth / 2}
                    cy={circleWidth / 2}
                    strokeWidth={strokeWidth}
                    r={radius}
                    className='circle-background'
                />
                <circle
                    cx={circleWidth / 2}
                    cy={circleWidth / 2}
                    strokeWidth={strokeWidth}
                    r={radius}
                    className='circle-progress'
                    style={{
                        strokeDasharray: dashArray,
                        strokeDashoffset: dashOffset
                    }}
                    transform={`rotate(-90 ${circleWidth / 2} ${circleWidth / 2})`}
                />
                <text x="50%" y="50%" dy="0.3em" textAnchor='middle' className='text-circle'>
                    {`${percentage}%`}
                </text>
            </svg>
        </div>
    )
}

export default CircleProgressBar;
