import React, { useState } from 'react';
import '../../utils/css/components/general/searchBar.css'
import IconSearch from '../../assets/icon/search.png'

const SearchBar = ({ onSearch }) => {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        onSearch(e.target.value);
    };

    return (
        <div className="search-bar-container">
            <img className='icon-searchBar' src={IconSearch}/>
            <input
                type="text"
                placeholder="Rechercher"
                value={inputValue}
                onChange={handleInputChange}
                className='input-searchBar'
            />
        </div>
    );
};

export default SearchBar;
