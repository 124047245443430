import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../utils/css/views/connexion.css';
import logo from '../assets/img/logo-prescription-nature-HD 1.png'
import { useGlobalState } from '../views/GlobalStateContext';
import team from '../assets/img/team.png'
import close from '../assets/icon/close.png'

function Connexion() {
    let navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { urlAPI } = useGlobalState();
    const [isRegistered, setIsRegistered] = useState(false);

    useEffect(() => {
        const registered = localStorage.getItem('isRegistered');
        setIsRegistered(registered === 'true');
    
        const handleBeforeUnload = () => {
            localStorage.removeItem('isRegistered');
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        const registered = localStorage.getItem('isRegistered');
        setIsRegistered(registered === 'true');
    }, []);
    
    const clearIsRegisteredLocalStorage = () => {
        localStorage.removeItem('isRegistered');
        setIsRegistered(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        fetch(urlAPI+'/auth/signin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataRequestSignin),
        })
        .then(response => {
            if (response.status === 401 || response.status === 500) {
                return response.json().then(data => {
                    setErrorMessage(data.message);
                    throw new Error(data.message);
                });
            }
            return response.json();
        })
        .then(data => {
            localStorage.setItem('userToken', data.token);
            navigate('/tableau_de_bord');
        })
        .catch((error) => {
            if (error instanceof TypeError && error.message === "Failed to fetch") {
                setErrorMessage("Nous rencontrons des difficultés pour nous connecter au serveur. Veuillez vérifier votre connexion Internet ou réessayer plus tard.");
            }
            // navigate('/error');
        });
    };
    
    

    const handleClick = (event) => {
        event.preventDefault();
        navigate('/inscription');
    }

    const handleMenuClick = (path) => {
        navigate(path);
    };


    const dataRequestSignin = {
        "email": email,
        "password": password
    }

    return (
        <div className='container-connexion-field'>
            { isRegistered ? (
                <div className='container-success'>
                    <img className='img-success' src={team}/>
                    <div className='container-text-success'>
                        <p>Votre demande d’inscription a bien été prise en compte. Notre équipe revient très vite vers vous !</p>
                    </div>
                    <div className='container-close'>
                        <img src={close} onClick={clearIsRegisteredLocalStorage}/>
                    </div>
                </div>
            ) : (
                <></>
            ) }

            <p className='connexion-text'> Connectez vous avec l'adresse e-mail et le mot de passe renseignés lors de votre inscription.</p>
            <img className="prescription-nature-logo" src={logo} alt="logo prescription nature" />
          
            <div className='container-label-text-field'>
                <form onSubmit={handleSubmit}>
                    <div className='container-label-input'>
                        <label className="label-input" htmlFor="email">Email</label>
                        <input
                            type="email"
                            className="input-first"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className='container-label-input'>
                        <label className="label-input" htmlFor="password">Mot de passe</label>
                        <input
                            type="password"
                            className="input"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <p onClick={() => handleMenuClick('/mot_de_passe')} className={errorMessage ? 'forget-password-error' : 'forget-password'}>Mot de passe oublié ?</p>
                    </div>
                    {errorMessage && <p className="error-message-connexion">{errorMessage}</p>}
                    <div className='container-btn'>
                        <button className="submit-btn" type="submit">Se connecter</button>
                        <p onClick={handleClick} className='text-inscription'>Inscription</p>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Connexion;
