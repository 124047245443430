import React, { useState } from 'react';
import iconArrowDown from '../../assets/icon/arrow_down.png';
import iconClip from '../../assets/icon/paperclip.png';
import '../../utils/css/components/documents/collapsibleComponent.css';
import { useGlobalState } from '../../views/GlobalStateContext';

const CollapsibleComponent = ({ title, items, searchTerm }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { urlAPI } = useGlobalState();

  const userToken = localStorage.getItem('userToken');

  const toggleOpen = () => setIsOpen(!isOpen);

  // Filtre les items basés sur le searchTerm et searchColumn.
  const filteredItems = items.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  function base64ToBlob(base64Data) {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
  
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
  
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray]);
  }
  

  const handleDownload = async (item, index) => {
    try {
      const url = `${urlAPI}/s3/download`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "folderName": "challenges",
          "eTag": item.eTag
        }),
      });
      if (!response.ok) {
        throw new Error('Problème lors de la création du document');
      }
  
      const data = await response.json();
      const blob = base64ToBlob(data.base64Data.base64Data);
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = data.base64Data.fileName; 
      downloadLink.click();
    } catch (error) {
      console.error(error);
    }
  }
  
  

  return (
    <div className="collapsible-component">
      <div className="header pointer" onClick={toggleOpen}>
        <h2 className='text-title-collaps'>{title}</h2>
        <img src={iconArrowDown} alt="arrow" style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }} />
      </div>
      {isOpen && (
        filteredItems.length > 0 ? (
          filteredItems.map((item, index) => {
            // Extraire l'extension du fichier
            const fileExtension = item.name.split('.').pop();
            // Extraire le nom du fichier sans l'extension
            const fileNameWithoutExtension = item.name.slice(0, -fileExtension.length - 1);
            // Raccourcir le nom du fichier à 15 caractères sans inclure l'extension
            const shortFileName = fileNameWithoutExtension.length > 15 ? fileNameWithoutExtension.substring(0, 15) : fileNameWithoutExtension;
            return (
              <div key={index} className='container-document-box'>
                <div className='header-document-box'>
                  <p className='text-document-box-title'>Nom</p>
                  <p className='text-document-box-title'>Date de publication</p>
                </div>
                <div className='container-tab-collaps'>
                  <div className='container-tab-collaps-text first'>
                  <p className='text-document-box one'>{shortFileName}.{fileExtension}</p>
                  </div>
                  <div className='container-tab-collaps-text second'>
                  <p className='text-document-box two'>{item.date}</p>
                  </div>
                    <div className="link-document-box-container three" onClick={() => handleDownload(item, index)}>
                      <img className='pointer' src={iconClip} alt="Voir la pièce jointe" />
                      <label className='link-document-box pointer'>Voir la pièce jointe</label>
                    
                  </div>
                </div>
              </div>
            );
          })          
        ) : (
          <p className='text-document-box'>Vous n'avez pas de document pour cette section.</p>
        )
      )}
    </div>
  );
};

export default CollapsibleComponent;
