import React, { useEffect, useState } from 'react';
import '../../utils/css/components/challenges/globalDoc.css';
import DragDropFile from './DragDropFile';
import addFileIcon from '../../assets/icon/add_file.png';
import paperClip from '../../assets/icon/voir_pdv.png';
import close_dark from '../../assets/icon/close_dark.png';
import Modal from '../../components/general/Modal';
import { useGlobalState } from '../../views/GlobalStateContext';
import { useParams } from 'react-router-dom';

const FileHandler = ({ onFileAdded }) => {
  const [fileName, setFileName] = useState("");
  const [fileBase64, setFileBase64] = useState("");
  const [showDragDrop, setShowDragDrop] = useState(false);
  const [modalOuvert, setModalOuvert] = useState(false);
  const { urlAPI } = useGlobalState();
  const userToken = localStorage.getItem('userToken');
  const [idDocumentCurrent, setIdDocumentCurrent] = useState("");
  const { id } = useParams();

  useEffect(() => {
    fetchMainDocument();
  }, []);

  const fetchMainDocument = async () => {
    const url = `${urlAPI}/document/getMainDocument/${id}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${userToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Problème lors de la récupération des données du document général');
      }
      let data = await response.json();
      setIdDocumentCurrent(data.document.id);
      setFileName(data.document.name); 
      setFileBase64(data.base64Data);
    } catch (error) {
      console.error(error);
    }
  };

  function base64ToBlob(base64Data) {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray]);
  }

  const handleFileAdd = (name, base64) => {
    setFileName(name);
    setFileBase64(base64);
    onFileAdded({ name, base64 });
    setModalOuvert(true);
  };

  const handleFileDownload = () => {
    if (fileBase64) {
      const blob = base64ToBlob(fileBase64);
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = fileName;
      downloadLink.click();
    }
  };

  const handleAddClick = () => {
    setShowDragDrop(true);
  };

  const toggleModal = () => {
    setModalOuvert(!modalOuvert);
  };

  const handleSendFileToS3 = async () => {
    try {
      const response = await fetch(urlAPI + '/s3/upload', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'fileName': fileName,
          'base64': fileBase64,
          "folderName": "challenges"
        }),
      });
      if (response.status == 413) { // Erreur renvoyée par nginx
        alert('Le fichier dépasse la taille maximale autorisée de 20 Mo.');
      }

      const data = await response.json();
      return data.eTag;
    } catch (error) {
      throw new Error('Problème lors de l\'envoi du fichier vers S3');
    }
  };

  const handleCreateDocument = async (eTag) => {
    try {
      const response = await fetch(urlAPI + '/document/createDocument', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${userToken}`,
        },
        body: JSON.stringify({
          "name": fileName,
          "id_challenges": id,
          "eTag": eTag,
          "id_document_current": idDocumentCurrent
        }),
      });

      if (!response.ok) {
        throw new Error('Problème lors de la création du document');
      }

      const data = await response.json();
      return data.id_document;
    } catch (error) {
      throw new Error('Problème lors de la création du document');
    }
  };

  const handleDoc = async () => {
    try {
      let eTag = await handleSendFileToS3();
      await handleCreateDocument(eTag);
      setModalOuvert(false);
      setShowDragDrop(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteFile = async () => {
    setFileName('');
    setFileBase64('');
  };

  return (
    <>
      <div className='container-depot-doc'>
        {(showDragDrop || !fileName) && (
          <div className='add-document pointer' onClick={handleAddClick}>
            <div className='add-document-doc'>
              <img src={addFileIcon} alt="Ajouter fichier" />
              <p>Ma preuve de vente</p>
            </div>
            {(!fileName || showDragDrop) && (
              <DragDropFile
                onGetFileName={(name) => handleFileAdd(name, fileBase64)}
                onGetFileBase64={(base64) => setFileBase64(base64)}
              />
            )}
          </div>
        )}

        {fileName && !showDragDrop && (
          <div className="view-document">
            <div className='add-document-doc'>
              <p>Ma preuve de vente</p>
              <img src={close_dark} className='close-button' onClick={deleteFile} />
            </div>
            <div className='container-view-doc'> 
              <img src={paperClip} alt="Visualiser fichier" className='file-icon pointer' onClick={handleFileDownload} />
              <p className='view-file-link pointer' onClick={handleFileDownload}>
                Voir la preuve de vente
              </p>
            </div>
          </div>
        )}
      </div>
      {modalOuvert && (
        <Modal isOpen={modalOuvert} onClose={toggleModal}>
          <p className='text-modal'>Voulez-vous valider l'enregistrement de votre document ?</p>
          <p className='text-modal-ask'>Confirmez ?</p>
          <div className='container-button-modal'>
            <button className='button-modal-second' onClick={toggleModal}>Annuler</button>
            <button className='button-modal' onClick={handleDoc}>
              Confirmer
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default FileHandler;
