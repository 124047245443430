import React from 'react';
import '../../utils/css/components/challenges/requiredElement.css'
import iconRequiredElement from '../../assets/icon/warning.png'

const RequiredElement = (props) => {

  return (
    <div className='container-requiredElement'>
        <img className='icon-requiredElement' src={iconRequiredElement} />
        <label className='text-requiredElement'>Preuve de vente</label>
    </div>
  );
};

export default RequiredElement;
