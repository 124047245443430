import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const BarChart = ({ chartData }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    let chartInstance = null;

    if (chartRef.current !== null) {
      const ctx = chartRef.current.getContext('2d');

      // Définir une fonction pour alterner entre les couleurs
      const alternatingColors = (context) => {
        const index = context.dataIndex;
        return index % 2 === 0 ? '#7CB4A8' : '#1B1C3C';
      };

      chartInstance = new Chart(ctx, {
        type: 'bar',
        data: {
          ...chartData,
          datasets: chartData.datasets.map(dataset => ({
            ...dataset,
            backgroundColor: alternatingColors,
          })),
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 1,
          height: '80%',
          layout: {
            padding: {
              top: 10,
              left: 20,
              right: 20,
            },
          },
          scales: {
            y: {
              display: false,
              ticks: {
                display: false, // Supprimer les labels de l'axe y
              },
            },
            x: {
              grid: {
                display: false,
              },
              ticks: {
                display: false, // Supprimer les labels de l'axe y
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              position: 'top',
              align: 'start',
              text: 'Gains triés par challenge',
              font: {
                size: '18vw',
                weight: 'bold',
              },
            },
          },
          barThickness: 60,
        },
      });
    }

    return () => {
      if (chartInstance !== null) {
        chartInstance.destroy();
      }
    };
  }, [chartData]);

  return <canvas ref={chartRef} />;
};

export default BarChart;
