import React from 'react';
import '../../utils/css/components/challenges/statusChallenge.css';

const StatusChallenge = (props) => {
    let fillColor = '';

    switch (props.status) {
        case 'A compléter':
            fillColor = '#ff0000';
            break;
        case 'Complet':
            fillColor = '#008000';
            break;
        case 'Validation - incomplet':
            fillColor = '#ff0000';
            break;
        case 'En cours de validation':
            fillColor = '#ffa500';
            break;
        case 'Validé':
            fillColor = '#008000';
            break;
        default:
            fillColor = '#000000';
    }

    return (
        <div className='container-status'>
            <svg className="svg-status" width="10" height="10" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5" cy="5" r="4" fill={fillColor} />
            </svg>
            <label>{props.status}</label>
        </div>
    );
};

export default StatusChallenge;
