import React, { useEffect, useState } from 'react';
import '../utils/css/views/homePage.css';
import Sidebar from '../components/general/Sidebar';
import Header from '../components/general/Header';
import MoreInfo from '../components/general/MoreInfo';
import CircleProgressBar from '../components/general/CircleProgressBar';
import challenge_trimestriel from '../assets/img/challenges/challenge_trimestriel.webp';
import challenge_exceptionnel from '../assets/img/challenges/bandeau-homepage/challenge vitalite.webp'
import pubZCalm from '../assets/img/pub/pub-zz-calm.webp'
import { useGlobalState } from '../views/GlobalStateContext';
import Loader from '../components/general/LoaderGIF';

function HomePage() {
  const [percentage, setPercentage] = useState(0);
  const [dataCurrent, setDataCurrent] = useState({
    current_challenge_trimestriel: null,
    current_challenge_exceptionnel: null,
  });
  const [isLoading, setIsLoading] = useState(true);

  const userToken = localStorage.getItem('userToken');
  const { urlAPI } = useGlobalState();

  useEffect(() => {
    fetchDetailsChall();
  }, []);

  useEffect(() => {
    if (dataCurrent.current_challenge_exceptionnel &&
      dataCurrent.current_challenge_exceptionnel.qtt !== undefined &&
      dataCurrent.current_challenge_exceptionnel.total_obj !== undefined &&
      dataCurrent.current_challenge_exceptionnel.total_obj !== 0) {
      const newPercentage = Math.round(
        (dataCurrent.current_challenge_exceptionnel.qtt * 100) /
        dataCurrent.current_challenge_exceptionnel.total_obj
      );
      setPercentage(newPercentage);

    }
  }, [dataCurrent.current_challenge_exceptionnel]);

  const fetchDetailsChall = async () => {
    const url = `${urlAPI}/challenge/getAllSummaryChallenge`;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': userToken,
            },
        });
        if (!response.ok) {
            throw new Error('Problème lors de la récupération des données challenges');
        }
        let data = await response.json();

        const challengesTrimestriels = data.filter(challenge => challenge.type === "0")[0] || null;
        const challengesExceptionnels = data.filter(challenge => challenge.type === "1" || challenge.type === "2")[0] || null;

        setDataCurrent({
            current_challenge_trimestriel: challengesTrimestriels,
            current_challenge_exceptionnel: challengesExceptionnels,
        });

    } catch (error) {
        console.error(error);
    } finally {
        setIsLoading(false);
    }
};


  const slider = [
    // <>
    //   {dataCurrent.current_challenge_trimestriel && (
    //     <>
    //       <img key="challenge_trimestriel" className='img-bandeau-container' src={challenge_trimestriel} alt="Challenge" />
    //       <div className='img-bandeau-container-title'>
    //         <h1>{dataCurrent.current_challenge_trimestriel.name}</h1>
    //         <h2>du {dataCurrent.current_challenge_trimestriel.start_date} au {dataCurrent.current_challenge_trimestriel.end_date}</h2>
    //       </div>
    //     </>
    //   )}
    // </>,
    <>
      <img key="challenge_exceptionnel" className='img-bandeau-container' src={challenge_exceptionnel} alt="Challenge" />
    </>,
    <>
      <img key="challenge_exceptionnel" className='img-bandeau-container' src={challenge_trimestriel} alt="Challenge" />
    </>
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % slider.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='container-homePage'>
      <Sidebar currentPage={"Tableau de bord"} />
      <div className='container-all-data'>
        <Header title={"tableau de bord"} bold={"tableau"} />
        <div className='container-all-data-without-header'>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div className='image-bandeau'>
                <div className='slider-dots'>
                  {slider.map((_, index) => (
                    <div key={index} className={`slider-dot ${index === currentIndex ? 'active' : ''}`} onClick={() => setCurrentIndex(index)} />
                  ))}
                </div>
                {slider[currentIndex]}
              </div>
              <div className='container-all-info'>
                <div className='container-challenge'>
                  <h2 className='subtitle-challenge'>Mes challenges</h2>
                  {dataCurrent.current_challenge_trimestriel && (
                    <div className='container-box'>
                      <div className='container-element-box'>
                        <div className='subtitle-box'>
                          <p className='subtitle-intro'>Challenge trimestriel :</p>
                          <p className='subtitle-intro more-weight'>{dataCurrent.current_challenge_trimestriel.name}</p>
                        </div>
                        <p className='text-gain'>Gains au {new Date().toLocaleDateString()}</p>
                        <div className='container-moreInfo-value'>
                          <p className='text-value-gain'>{dataCurrent.current_challenge_trimestriel.gain}€</p>
                        </div>
                        <MoreInfo
                          id={dataCurrent.current_challenge_trimestriel.id}
                          type={dataCurrent.current_challenge_trimestriel.type}
                          limitDate={dataCurrent.current_challenge_trimestriel.limit_date}
                          name={dataCurrent.current_challenge_trimestriel.name}
                          path={`/challenges/challenge_trimestriel_details/`}
                        />
                      </div>
                    </div>
                  )}
                  {dataCurrent.current_challenge_exceptionnel && (
                    <div className='container-box'>
                      <div className='subtitle-box'>
                        <p className='subtitle-intro'>Challenge du moment: {dataCurrent.current_challenge_exceptionnel.name}</p>
                      </div>
                      <div className='container-moreInfo-value'>
                        <div className='container-progressBar-text'>
                          <CircleProgressBar percentage={percentage >= 100 ? 100 : percentage} circleWidth="110" />
                          <div className='container-text-unit'>
                          <p className='text-unit'>
                              {dataCurrent.current_challenge_exceptionnel.qtt >= dataCurrent.current_challenge_exceptionnel.total_obj ? "Objectif atteint !" : `Plus que ${dataCurrent.current_challenge_exceptionnel.total_obj - dataCurrent.current_challenge_exceptionnel.qtt} unités à vendre`}
                          </p>
                          </div>
                        </div>
                      </div>
                      <MoreInfo
                        id={dataCurrent.current_challenge_exceptionnel.id}
                        type={dataCurrent.current_challenge_exceptionnel.type}
                        limitDate={dataCurrent.current_challenge_exceptionnel.limit_date}
                        name={dataCurrent.current_challenge_exceptionnel.name}
                        path={`/challenges/challenge_exceptionnel_details/`}
                      />
                    </div>
                  )}
                </div>
                <div>
                    <img src={pubZCalm} className='img-pub-chall' />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}


export default HomePage;