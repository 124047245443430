import React from 'react';
import BarChart from './BarChartStats';

function ChalStats({ chartData, gain, uvc, ca_trim }) {
    return (
            <div className='container-chart-value'>
                <div className='container-chart'>
                    <BarChart chartData={chartData} />
                </div>
                <div className='container-value'>
                    <p className='text-chart'>Gains en euros : <span className='text-chart-bold'>{gain} €</span></p>
                    <p className='text-chart'>UVC vendues : <span className='text-chart-bold'>{uvc}</span></p>
                    <p className='text-chart'>CA trimestriel : <span className='text-chart-bold'>{ca_trim} €</span></p>
                </div>
            </div>
            
   
    );
}

export default ChalStats;

