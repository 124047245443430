import React, { useState, useEffect } from 'react';
import '../../utils/css/components/challenges/listingProducts.css';
import ProductCard from '../general/ProductCard';

const ListingProducts = (props) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [productsPerPage, setProductsPerPage] = useState(4);

    const totalPages = Math.ceil(props.data.length / (productsPerPage * 2)); 

    const displayProducts = () => {
        const startIndex = currentPage * productsPerPage * 2;
        const endIndex = startIndex + productsPerPage * 2;
        return props.data.slice(startIndex, endIndex);
    };

    const nextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
    };

    const prevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1400) {
                setProductsPerPage(3);
            } else {
                setProductsPerPage(4);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='listing-products'>
            <div className='container-listing-products'>
                {displayProducts().map((produit, produitIndex) => (
                    <ProductCard
                        key={produitIndex}
                        name={produit.name}
                        image={produit.image}
                        qty_sell={produit.qty_sell}
                        qty_totale={produit.qty_totale}
                    />
                ))}
            </div>
            <div className="pagination">
                <button className='btn-page-products' onClick={prevPage} disabled={currentPage === 0}>{"<<<"}</button>
                <span className='text-pagination'>{currentPage + 1} / {totalPages}</span>
                <button className='btn-page-products' onClick={nextPage} disabled={currentPage === totalPages - 1}>{">>>"}</button>
            </div>
        </div>
    );
};

export default ListingProducts;
