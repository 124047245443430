import React, { useState, useRef, useEffect } from 'react';
import '../../utils/css/views/ventes.css';
import uploadFile from '../../assets/icon/upload_file.png';
import deleteIcon from '../../assets/icon/delete.png';
import addBlueIcon from '../../assets/icon/add_circle_blue.png';

function DragDropFile({ onGetFileName, onGetFileBase64, curentFileName }) {
    const [dragActive, setDragActive] = useState(false);
    const [fileName, setFileName] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        if (curentFileName !== '') {
            setFileName(curentFileName);
        }
    }, [curentFileName]);

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(e.type === "dragenter" || e.type === "dragover");
    };

    const handleFile = (file) => {
        const extension = file.name.split('.').pop().toLowerCase();
        if (extension !== 'jpg' && extension !== 'pdf') {
            alert("Les fichiers doivent être en format .jpg ou .pdf");
            return;
        }

        const reader = new FileReader();
        reader.onload = (event) => {
            const base64 = event.target.result.split(',')[1];
            onGetFileName(file.name);
            setFileName(file.name);
            onGetFileBase64(base64);
        };
        reader.readAsDataURL(file);
    };

    const handleChange = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        if (file) {
            handleFile(file);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFile(e.dataTransfer.files[0]);
        }
    };

    const handleRemoveFile = () => {
        setFileName('');
        inputRef.current.value = null;
    };

    const openFileDialog = () => {
        inputRef.current.click();
    };

    const handleClickContainer = (e) => {
        e.stopPropagation();
        openFileDialog();
    };

    return (
        <div>
            <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                <input ref={inputRef} type="file" id="input-file-upload" style={{ display: 'none' }} multiple={false} onChange={handleChange} />
            </form>
            {fileName ? (
                <div className='container-info-depot' onDrop={handleDrop} onDragEnter={handleDrag} onDragOver={handleDrag}>
                    <div className="file-info">
                        <p>{fileName.length > 10 ? fileName.slice(0, 10) + '...' : fileName}</p>
                        <img src={deleteIcon} alt="Close icon" onClick={handleRemoveFile} />
                    </div>
                </div>
            ) : (
                <div className='container-info-depot' onClick={handleClickContainer} onDrop={handleDrop} onDragEnter={handleDrag} onDragOver={handleDrag}>
                    <img src={uploadFile} alt="Upload icon" />
                    <p>Formats acceptés <br /> .JPG, .PDF <br /> (10Mo max.)</p>
                </div>
            )}
            <div className='depot-file-subtitle' onClick={openFileDialog}>
                <img src={addBlueIcon} />
                <p>Ajouter une preuve de vente</p>
            </div>
            {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
        </div>
    );
}

export default DragDropFile;
