import React, { useState, useEffect } from 'react';
import Sidebar from '../components/general/Sidebar';
import Header from '../components/general/Header';
import '../utils/css/views/ventes.css';
import { useNavigate } from 'react-router-dom';
import ListingVentes from '../components/challenges/ListingVentes';
import DragDropFile from '../components/challenges/DragDropFile';
import Modal from '../components/general/Modal';
import warningIcon from '../assets/icon/warning.png';
import { useGlobalState } from '../views/GlobalStateContext';
import { useLocation } from 'react-router-dom';
import Loader from '../components/general/LoaderGIF';
import { useParams } from 'react-router-dom';

function Ventes() {
    const navigate = useNavigate();
    const columns = ['Produit', 'Quantité', 'Gain', 'Gain total', 'Prix HT'];

    const location = useLocation();
    const { name, limitDate, type, id_chall } = location.state || {};
    const dataDocument = {}

    const [ventesData, setVentesData] = useState('');
    const [fileName, setFileName] = useState('');
    const [fileBase64, setFileBase64] = useState('');
    const [userPharmacy, setUserPharmacy] = useState();
    const [modalOuvert, setModalOuvert] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaleAccountChecked, setIsSaleAccountChecked] = useState(false);

    const userToken = localStorage.getItem('userToken');
    const { urlAPI } = useGlobalState();

    const { id } = useParams();

    const fileData = {
        'fileName': fileName,
        'base64': fileBase64,
        "folderName": "challenges"
    }

    useEffect(() => {
        fetchUserDetails();
    }, []);

    useEffect(() => {
        if (userPharmacy) {
            fetchDetailsSell();
        }
    }, [userPharmacy]); // Utilise userPharmacy comme dépendance

    const fetchDetailsSell = async () => {
        const nom_client = encodeURIComponent(userPharmacy);
        const url = `${urlAPI}/sell/getAllProcutForOnePharmacy?nom_client=${nom_client}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${userToken}`,
                },
            });
            if (!response.ok) {
                throw new Error('Problème lors de la récupération des produits');
            }
            let data = await response.json();
            // Traitement et mise à jour de ventesData ici
            const transformedData = data.data.map((product, index) => ({
                id: index,
                Produit: product.name,
                Quantité: 0,
                'Gain': product.gain,
                'Gain total': (product.price > 10 ? 1 : 0.5) * 1,
                'Prix HT': product.price,
            }));
            setVentesData(transformedData);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setVentesData([]);
            setIsLoading(false);
        }
    };

    const fetchUserDetails = async () => {
        const url = `${urlAPI}/user/getUserWithToken`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${userToken}`,
                },
            });
            if (!response.ok) {
                throw new Error('Problème lors de la récupération des données de l\'utilisateur');
            }
            const data = await response.json();
            setUserPharmacy(data.pharmacy);
        } catch (error) {
            console.error('Erreur lors de la récupération des détails de l’utilisateur:', error);
        }
    };

    const handleSaveRequest = () => {
        handleModif();
    };

    const toggleModal = () => {
        setModalOuvert(!modalOuvert);
    };

    const handleModif = () => {
        setModalOuvert(true);
    };

    const handleSaleAccountChange = (event) => {
        setIsSaleAccountChecked(event.target.checked);
    };

    const handleFileName = (fileName) => {
        setFileName(fileName);
    };

    const handleFileBase64 = (fileBase64) => {
        setFileBase64(fileBase64);
    };


    const handleCreateDocument = async (eTag) => {
        try {
            const response = await fetch(urlAPI + '/document/createDocument', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${userToken}`,
                },
                body: JSON.stringify({
                    "name": fileName,
                    "id_challenges": id,
                    "eTag": eTag
                }),
            });

            if (!response.ok) {
                throw new Error('Problème lors de la création du document');
            }

            const data = await response.json();
            return data.id_document;
        } catch (error) {
            throw new Error('Problème lors de la création du document');
        }
    };

    const handleSendFileToS3 = async () => {
        try {
            const response = await fetch(urlAPI + '/s3/upload', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(fileData),
            });

            if (!response.ok) {
                throw new Error('Problème lors de l\'envoi du fichier vers S3');
            }

            if (response.status == 413) { // Erreur renvoyée par nginx
                alert('Le fichier dépasse la taille maximale autorisée de 20 Mo.');
              }

            const data = await response.json();
            return data.eTag;
        } catch (error) {
            throw new Error('Problème lors de l\'envoi du fichier vers S3');
        }
    };


    const handleCreateSell = async (id_document, filteredData) => {
        const url = `${urlAPI}/sell/createSell`;
        try {

            if (filteredData.length === 0) {
                setModalOuvert(!modalOuvert);
                navigate(-1);
                return;
            }

            console.log(filteredData);

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${userToken}`,
                }, body: JSON.stringify({
                    id_challenges: id,
                    id_document: id_document,
                    dataSell: filteredData,
                    isBeneficiary: isSaleAccountChecked
                }),
            });
            if (response.status === 400) {
                setModalOuvert(!modalOuvert);
                throw new Error('Non autorisé');
            }
    
            if (!response.ok) {
                throw new Error('Problème lors de la création du document');
            }
            const data = await response.json();
        } catch (error) {
            throw new Error('Problème lors de la requête de création de vente');
        }
        setModalOuvert(!modalOuvert);
        navigate(-1);
    };

    const filtrerVentes = () => {
        return ventesData.filter(vente => vente.Quantité !== 0);
    };

    const handleSendData = async () => {
        try {

            if (isSaleAccountChecked && (!fileName || fileName === '') && (!fileBase64 || fileBase64 === '')) {
                console.log("Vous devez ajouter un fichier si vous avez coché");
            }

            const filteredData = filtrerVentes()

            if (fileName && fileBase64) {
                let eTag = await handleSendFileToS3();
                let id_document = await handleCreateDocument(eTag);
                await handleCreateSell(id_document, filteredData);
            } else {
                await handleCreateSell(null, filteredData);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className='container-challenges'>
            <Sidebar currentPage={"Challenges"} />
            <div className='container-all-data-challenges'>
                <Header title={name} back={true} />
                {type == 0 && (
                    <p className='depot-file-warning'> <img src={warningIcon} alt="icon warning" className="iconVentes" /> Pour les challenges trimestriels, vous êtes requis de soumettre le document une seule fois par challenge. <br/>Vous pouvez toujours modifier cette vente dans votre l'historique du challenge</p>
                )}
                {(type == 1 || type == 2) && (
                    <p className='depot-file-warning'> <img src={warningIcon} alt="icon warning" className="iconVentes" />Lorsque vous affectez une vente à votre compte, il est obligatoire de fournir un document à chaque création de vente. Si vous choisissez de ne pas affecter la vente à votre compte, veuillez soumettre un unique document global pour l'ensemble du challenge.</p>
                )}
                <div className='container-without-header'>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <div className='container-subtitle-ventes'>
                                <h2 className='subtitle-ventes'>Ajouter mes ventes</h2>
                            </div>
                            <div className='big-container-ventes'>
                                <ListingVentes
                                    columns={columns}
                                    data={ventesData || []}
                                    onGetData={(getData) => setVentesData(getData)}
                                />
                                {type != 0 && (
                                    <div className='container-depot-file'>
                                        <h3 className='depot-file-title'>Preuve de vente</h3>
                                        <DragDropFile
                                            onGetFileName={handleFileName}
                                            onGetFileBase64={handleFileBase64}
                                            curentFileName={dataDocument['fileName']}
                                        />
                                    </div>
                                )}
                            </div>
                            {(Number(type) === 1 || Number(type) === 2) && (
                                <div className="checkbox-container">
                                    <input
                                        type="checkbox"
                                        id="accountSale"
                                        name="accountSale"
                                        checked={isSaleAccountChecked}
                                        onChange={handleSaleAccountChange}
                                    />
                                    <label htmlFor="accountSale" className="checkbox-label">
                                        Souhaitez-vous affecter cette vente à votre compte ?
                                    </label>
                                </div>
                            )}
                            <div className='container-button-save-data-ventes'>
                                <button className='button-save-data-ventes' onClick={handleSaveRequest}>Enregistrer mes ventes</button>
                            </div>
                        </>
                    )}
                </div>
                {modalOuvert && (
                    <Modal isOpen={modalOuvert} onClose={toggleModal}>
                        <p className='text-modal'>Vous devez encore rajouter votre preuve de vente. Elle peut être rajoutée jusqu’au {limitDate}.</p>
                        <p className='text-modal-ask'>Confirmez ?</p>
                        <div className='container-button-modal'>
                            <button className='button-modal-second' onClick={toggleModal}>Annuler</button>
                            <button className='button-modal' onClick={handleSendData}>
                                Confirmer
                            </button>
                        </div>
                    </Modal>
                )}
            </div>
        </div>
    );

}

export default Ventes;