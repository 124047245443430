import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../utils/css/views/invitationBeneficiaire.css';
import ValidPassword from '../components/auth/ValidPassword';
import logo from '../assets/img/logo-prescription-nature-HD 1.png';
import iconInfo from '../assets/icon/information.png';
import Modal from '../components/general/Modal';
import { useGlobalState } from '../views/GlobalStateContext';

function InvitationBeneficiaire() {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const customerCode = searchParams.get('customerCode');

    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [mail, setMail] = useState('');
    const [codeClient, setCodeClient] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [boolPass, setBoolPass] = useState('');
    const [modalOuvert, setModalOuvert] = useState(false);
    const [pharmacyName, setPharmacyName] = useState('');
    const { urlAPI } = useGlobalState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(urlAPI+'/organization/getPharmacyByCode', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "customerCode": customerCode
                    }),
                });
        
                if (!response.ok) {
                    throw new Error('Problème lors de la récupération des informations de la pharmacie.');
                }
        
                const data = await response.json();
                setPharmacyName(data.data.name);
            } catch (error) {
                throw new Error('Problème lors de la récupération des informations de la pharmacie.');
            }
        };

        fetchData();
    }, [customerCode, urlAPI]);

    const comparePassord = () => {
        if(password === confirmPassword){
            setBoolPass(true)
            navigate('/');
        }else {
            setBoolPass(false)
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (password !== confirmPassword){
            alert("Le mot de passe n'est pas identique");
        } else if (!isValidPassword(password)) {
            setModalOuvert(!modalOuvert);
        } else {
            try {
                const response = await fetch(urlAPI+'/auth/signupBeneficiary', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        firstname: firstName,
                        lastname: lastName,
                        email: mail,
                        password: password,
                    }),
                });
    
                if (!response.ok) {
                    throw new Error('Problème lors de l\'inscription.');
                }
    
                navigate('/'); // Redirige vers la page d'accueil après une inscription réussie
            } catch (error) {
                console.error('Erreur lors de l\'inscription:', error);
                // Gérer l'erreur d'inscription, afficher un message d'erreur par exemple
            }
        }
    }
    

    const isValidPassword = (password) => {
        // Vérifie si le mot de passe a au moins 8 caractères, une majuscule, un chiffre et un caractère spécial.
        const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    };

    const toggleModal = () => {
        setModalOuvert(!modalOuvert);
    };

    return (
        <div className='container-invitation-beneficiaire'>
            <img src={logo} />
            <p className='text-pharmacie-name-invit'>{pharmacyName}</p>
            <p className='text-pharmacie-invit'>vous a invité à rejoindre son équipe !</p>
            <form onSubmit={handleSubmit} className='form-invitation-beneficiaire'>
                <div className='container-input-step-password'>
                    <label className='text-input-beneficiaire-invit'>Nom</label>
                    <input
                        type="text"
                        className="input-step-password"
                        id="email"
                        onChange={(e) => setLastName(e.target.value)}
                        required
                    />
                </div>
                <div className='container-input-step-password'>
                    <label className='text-input-beneficiaire-invit'>Prénom</label>
                    <input
                        type="text"
                        className="input-step-password"
                        id="email"
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                    />
                </div>
                <div className='container-input-step-password'>
                    <label className='text-input-beneficiaire-invit'>Adresse e-mail</label>
                    <input
                        type="email"
                        className="input-step-password"
                        id="email"
                        onChange={(e) => setMail(e.target.value)}
                        required
                    />
                </div>
                <div className='container-input-step-password'>
                    <label className='text-input-beneficiaire-invit'>Code client</label>
                    <input
                        type="text"
                        className="input-step-password"
                        id="code_client"
                        value={customerCode}
                        disabled 
                        required
                    />
                </div>
                <div className='container-label-input'>
                    <div className='container-inputPass-icon'>
                        <label className='text-input-beneficiaire-invit'>Créer votre mot de passe</label>
                        <img src={iconInfo} className="icon-information" alt="Information" onClick={toggleModal} />
                        <Modal isOpen={modalOuvert} onClose={toggleModal} firstButton={"Retour"} nbmButton={1}>
                            <p className='text-modal'>Votre mot de passe doit contenir au minimum : 8 caractères dont 1 majuscule, 1 chiffre, 1 caractère spécial</p>
                        </Modal>
                    </div>
                    <input
                        type="password"
                        className="input-step-password"
                        id="password"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <ValidPassword password={password} />
                <div className='container-input-step-password'>
                    <label className='text-input-beneficiaire-invit'>Confirmer votre mot de passe</label>
                    <input
                        type="password"
                        className="input-step-password"
                        id="password_confirm"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                <button className="submit-btn-inscription" type="submit">S'inscrire</button>
                {boolPass === false && (
                    <p className='wrong-code'>Vos deux mot de passes ne sont pas identique</p>
                )}
            </form>
        </div>
    )
}

export default InvitationBeneficiaire;