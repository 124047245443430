import React, { useState, useEffect } from 'react';
import Sidebar from '../components/general/Sidebar';
import Header from '../components/general/Header';
import '../utils/css/views/beneficiaires.css';
import SearchBar from '../components/general/SearchBar';
import ListingElement from '../components/general/ListingElement';
import pubCalm from '../assets/img/pub/zz-calm-pub-verticale.webp';
import Loader from '../components/general/LoaderGIF';
import { useGlobalState } from '../views/GlobalStateContext';

function Beneficiaires() {

    const [searchTerm, setSearchTerm] = useState('');
    const [beneficiary, setBeneficiary] = useState(true);
    const { urlAPI } = useGlobalState();
    const [dataBeneficiary, setDataBeneficiary] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const userToken = localStorage.getItem('userToken');

    const columns = ['Nom', 'Adresse e-mail'];

    const handleSearch = (searchValue) => {
        setSearchTerm(searchValue);
    };

    useEffect(() => {
        setIsLoading(true); 
        fetchBeneficiaryDetails();
        fetchAllBeneficiries();
    }, []);

    const fetchAllBeneficiries = async () => {
        fetch(urlAPI + "/organization/getAllcollaborators", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${userToken}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                setDataBeneficiary(data.data)
                setIsLoading(false); 
            })
            .catch((error) => {
                console.error('Error:', error);
                setIsLoading(false); 
            });
    }

    const fetchBeneficiaryDetails = async () => {
        const url = `${urlAPI}/organization/userIsBeneficiary`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${userToken}`,
                },
            });
            if (!response.ok) {
                throw new Error('Problème lors de la récupération des données de l\'utilisateur');
            }
            const data = await response.json();
            setBeneficiary(data.data)
        } catch (error) {
            console.error('Erreur lors de la récupération des détails de l’utilisateur:', error);
        }
    };

    return (
        <div className='container-beneficiaires'>
            <Sidebar currentPage={"Bénéficiaires"} />
            <div className='container-all-data-beneficiaires'>
                <Header title={"Mes bénéficiaires"} bold={"bénéficiaires"} />
                <div className='container-without-header-beneficiaires'>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <>
                    <div className='container-searchBar-elementList'>
                        <h2 className='subtitle-beneficiaires'>Liste des bénéficiaires</h2>
                        <SearchBar onSearch={handleSearch} />
                       
                            <ListingElement
                                columns={columns}
                                data={dataBeneficiary}
                                searchTerm={searchTerm}
                                searchColumn="Nom"
                                isBeneficiary={beneficiary}
                            />
                       
                    </div>
                    <div className='container-pub-beneficiaires'>
                        <img src={pubCalm} className='img-pub-beneficiaires'/>
                    </div>
                    </>
                     )}
                </div>
                
            </div>
        </div>
    )
}

export default Beneficiaires;


