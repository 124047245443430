import React, { useState } from 'react';
import '../utils/css/views/profilEmail.css';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/general/Sidebar';
import Header from '../components/general/Header';
import { useGlobalState } from '../views/GlobalStateContext';

function ProfilEmail() {

    let navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [password, setPassword] = useState('')
    const { urlAPI } = useGlobalState();
    const [errorMessage, setErrorMessage] = useState('');

    const userToken = localStorage.getItem('userToken');

    const handleValidate = (e) => {
        e.preventDefault();

        const updateProfilData = {
            email: email,
            newEmail: newEmail,
            password: password
        }

        fetch(urlAPI+"/user/updateProfil", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `${userToken}`,
            },
            body: JSON.stringify(updateProfilData),
          })
          .then(response => response.json())
          .then(data => {
            if(data.message =="Email mis à jour avec succès"){
                navigate('/profil')
            }else{
                setErrorMessage(data.message)
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
    }

    return (
        <div className='container-profilEmail'>
            <Sidebar />
            <div className='container-all-data-profilEmail'>
                <Header title={"Modifier mon adresse e-mail"} bold={"adresse e-mail"} back={true} />
                <div className='container-form-profilEmail'>
                    <form className='form-profil'>
                        <div className='container-label-input-first'>
                            <label className="label-input required">Adresse e-mail actuelle</label>
                            <input
                                type="email"
                                className="input"
                                id="email"
                                value={email}
                                placeholder={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className='container-label-input'>
                            <label className="label-input required">Nouvelle adresse e-mail</label>
                            <input
                                type="email"
                                className="input"
                                id="newEmail"
                                value={newEmail}
                                onChange={(e) => setNewEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className='container-label-input'>
                            <label className="label-input required">Mot de passe</label>
                            <input
                                type="password"
                                className="input"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div onClick={handleValidate} className='container-btn'>
                            {errorMessage && <p className="error-message-connexion">{errorMessage}</p>}
                            <button className="submit-btn-inscription" type="submit">Valider</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ProfilEmail;
