import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ValidPassword from '../components/auth/ValidPassword'
import Modal from '../components/general/Modal'
import '../utils/css/views/inscription.css';
import logo from '../assets/img/logo-prescription-nature-HD 1.png'
import iconInfo from '../assets/icon/information.png'
import { useGlobalState } from '../views/GlobalStateContext';

function InscriptionBeneficiaire() {
    let navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [prenom, setPrenom] = useState('');
    const [nom, setNom] = useState('');
    const [modalOuvert, setModalOuvert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { urlAPI } = useGlobalState();

    const isValidPassword = (password) => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const signupData = {
            firstname: prenom,
            lastname: nom,
            email: email,
            password: password
        };

        if (isValidPassword(password)) {
            fetch(urlAPI + "/auth/signupBeneficiary", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(signupData),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.message === "User created") {
                        navigate('/');
                    } else {
                        setErrorMessage(data.message);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        } else {
            setErrorMessage('Mot de passe invalide.');
        }
    };

    const handleClick = (event) => {
        event.preventDefault();
        navigate('/');
    };

    const toggleModal = () => {
        setModalOuvert(!modalOuvert);
    };

        return (
            <div className='container-connexion-field'>
                <img className="prescription-nature-logo" src={logo} alt="logo prescription nature" />
                <div className='container-label-text-field'>
                    <form onSubmit={handleSubmit}>
                        <div className='container-label-input'>
                            <label className="label-input ">Nom</label>
                            <input
                                type="text"
                                className="input"
                                id="email"
                                value={nom}
                                onChange={(e) => setNom(e.target.value)}
                                required
                            />
                        </div>
                        <div className='container-label-input'>
                            <label className="label-input">Prénom</label>
                            <input
                                type="text"
                                className="input"
                                id="email"
                                value={prenom}
                                onChange={(e) => setPrenom(e.target.value)}
                                required
                            />
                        </div>
                        <div className='container-label-input'>
                            <label className="label-input" htmlFor="email">Email</label>
                            <input
                                type="email"
                                className="input"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className='container-label-input'>
                            <div className='container-inputPass-icon'>
                                <label className="label-input" htmlFor="password">Mot de passe</label>
                                <img src={iconInfo} className="icon-information" alt="Information" onClick={toggleModal} />
                                <Modal isOpen={modalOuvert} onClose={toggleModal} firstButton={"Retour"} nbmButton={1}>
                                    <p className='text-modal'>Votre mot de passe doit contenir au minimum : 8 caractères dont 1 majuscule, 1 chiffre, 1 caractère spécial</p>
                                </Modal>
                            </div>
                            <input
                                type="password"
                                className="input"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <ValidPassword password={password} />
                        {errorMessage && <p className="error-message-connexion">{errorMessage}</p>}
                        <div className='container-btn'>
                            <button className="submit-btn-inscription pointer" type="submit">S'inscrire</button>
                            <p onClick={handleClick} className='text-inscription'>Se connecter</p>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    export default InscriptionBeneficiaire;