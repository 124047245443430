import React, { useState } from 'react';
import '../utils/css/views/profilPassword.css';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/general/Sidebar';
import Header from '../components/general/Header';
import ValidPassword from '../components/auth/ValidPassword';
import iconInfo from '../assets/icon/information.png'
import Modal from '../components/general/Modal'
import { useGlobalState } from '../views/GlobalStateContext';

function ProfilPassword() {
    const navigate = useNavigate();
    const [firstPassword, setFirstPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [modalOuvert, setModalOuvert] = useState(false);
    const { urlAPI } = useGlobalState();
    const [errorMessage, setErrorMessage] = useState('');

    const userToken = localStorage.getItem('userToken');

    const handleInputClick = () => {
        if (firstPassword === '******') {
            setFirstPassword('');
        }
    };

    const isValidPassword = (password) => {
        // Vérifie si le mot de passe a au moins 8 caractères, une majuscule, un chiffre et un caractère spécial.
        const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    };

    const handleValidate = (e) => {
        e.preventDefault();

        if (!isValidPassword(newPassword)) {
            setModalOuvert(true);
            return;
        }

        const updateProfilData = {
            password: firstPassword,
            newPassword: newPassword,
            confirmNewPassword: confirmNewPassword
        };

        fetch(`${urlAPI}/user/updateProfil`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${userToken}`,
            },
            body: JSON.stringify(updateProfilData),
        })
        .then(response => response.json())
        .then(data => {
            if(data.message === "Mot de passe mis à jour avec succès") {
                navigate('/profil');
            } else {
                setErrorMessage(data.message);
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };

    const toggleModal = () => {
        setModalOuvert(!modalOuvert);
    };

    return (
        <div className='container-profilPassword'>
            <Sidebar />
            <div className='container-all-data-profilPassword'>
                <Header title={"Modifier mon mot de passe"} bold={"mot de passe"} back={true} />
                <div className='container-form-profilEmail'>
                    <form onSubmit={handleValidate} className='form-profil'>
                        <div className='container-label-input-first'>
                            <label className="label-input required">Tapez votre mot de passe actuel</label>
                            <input
                                type="password"
                                className="input"
                                id="name"
                                value={firstPassword}
                                placeholder={firstPassword}
                                onChange={(e) => setFirstPassword(e.target.value)}
                                onClick={handleInputClick}
                                required
                            />
                        </div>

                        <div className='container-label-input'>
                            <div className='container-inputPass-icon'>
                                <label className="label-input required">Nouveau mot de passe</label>
                                <img src={iconInfo} className="icon-information" alt="Information" onClick={toggleModal} />
                                <Modal isOpen={modalOuvert} onClose={toggleModal} firstButton={"Retour"} nbmButton={1}>
                                    <p className='text-modal'>Votre mot de passe doit contenir au minimum : 8 caractères dont 1 majuscule, 1 chiffre, 1 caractère spécial</p>
                                </Modal>
                            </div>
                            <input
                                type="password"
                                className="input"
                                id="name"
                                value={newPassword}
                                placeholder={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                        </div>

                        <ValidPassword password={newPassword} />
                        <div className='container-label-input'>
                            <label className="label-input required">Confirmer le mot de passe</label>
                            <input
                                type="password"
                                className="input"
                                id="name"
                                value={confirmNewPassword}
                                placeholder={confirmNewPassword}
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                required
                            />
                        </div>
                        {errorMessage && <p className="error-message-updatePwd">{errorMessage}</p>}
                        <div className='container-btn'>
                            <button className="submit-btn-inscription" type="submit">Valider</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ProfilPassword;
