import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ValidPassword from '../components/auth/ValidPassword'
import Modal from '../components/general/Modal'
import '../utils/css/views/inscription.css';
import logo from '../assets/img/logo-prescription-nature-HD 1.png'
import iconInfo from '../assets/icon/information.png'
import Loader from '../components/general/LoaderGIF';
import loaderGif from '../assets/img/loading_circle.gif'
import eye_on from '../assets/icon/visibility_on.png'
import eye_off from '../assets/icon/visibility_off.png'
import { useGlobalState } from '../views/GlobalStateContext';

function Inscription() {
    let navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');
    const [codeClientError, setCodeClientError] = useState('');
    const [codeClient, setCodeClient] = useState('');
    const [prenom, setPrenom] = useState('');
    const [nom, setNom] = useState('');
    const [nomPharmacie, setNomPharmacie] = useState('');
    const [modalOuvert, setModalOuvert] = useState(false);
    const [modalOuvert2, setModalOuvert2] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingPharmacy, setIsFetchingPharmacy] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const { urlAPI } = useGlobalState();

    useEffect(() => {  
        if (codeClient.trim() === '') {
            setNomPharmacie('');
            setCodeClientError('');
            return;
        }
        setIsFetchingPharmacy(true);

        const fetchPharmacyName = async () => {
            try {
                const response = await fetch(`${urlAPI}/auth/pharmacyName`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ customerCode: codeClient }),
                });

                const data = await response.json();

                if (data.pharmacyName) {
                    setNomPharmacie(data.pharmacyName);
                    setCodeClientError('');
                } else {
                    setNomPharmacie('');
                    setCodeClientError('Code client non reconnu');
                }
            } catch (error) {

                setNomPharmacie('');
                setCodeClientError('Erreur de connexion ou problème de serveur');
            } finally {
                setIsFetchingPharmacy(false);
            }
        };
        
        fetchPharmacyName();
    }, [codeClient, urlAPI]);

    useEffect(() => {
        if (nom.trim() !== '') {
            setCodeClientError('');
        }
    }, [nom]);
    
    // Efface le message d'erreur lorsque le code client est effacé entièrement
    useEffect(() => {
        if (codeClient.trim() === '') {
            setCodeClientError('');
        }
    }, [codeClient]);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const signupData = {
            firstname: prenom,
            lastname: nom,
            email: email,
            customerCode: codeClient,
            pharmacy_name: nomPharmacie,
            password: password
          };

        if (password !== confirmPassword) {
            alert("Le mot de passe n'est pas identique");
        } else if (!isValidPassword(password)) {
            setModalOuvert(!modalOuvert);
        } else {
            setIsLoading(true);
            fetch(urlAPI+"/auth/signup", {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(signupData),
              })
              .then(response => response.json())
              .then(data => {
                if(data.message =="User created"){
                    setIsLoading(false);
                    localStorage.setItem('isRegistered', true);
                    navigate('/')
                }else{
                    setErrorMessage(data.message);
                    setIsLoading(false);
                }
              })
              .catch((error) => {
                console.error('Error:', error);
              });
        }
    };
   
    const isValidPassword = (password) => {
        // Vérifie si le mot de passe a au moins 8 caractères, une majuscule, un chiffre et un caractère spécial.
        const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    };

    const handleClick = (event) => {
        event.preventDefault();
        navigate('/');
    }

    const toggleModal = () => {
        setModalOuvert(!modalOuvert);
    };

    const toggleModal2 = () => {
        setModalOuvert2(!modalOuvert2);
    };

    return (
        <div className='container-connexion-field'>
            <img className="prescription-nature-logo" src={logo} alt="logo prescription nature" />
            {isLoading ? (
                <Loader />
            ) : (
                <div className='container-label-text-field'>
                    <form onSubmit={handleSubmit}>
                        <div className='container-label-input-first'>
                            <label className="label-input required">Code client</label>
                            <input
                                type="text"
                                className="input"
                                id="customerCode"
                                value={codeClient}
                                onChange={(e) => setCodeClient(e.target.value)}
                                required
                            />
                        </div>

                        {codeClientError && <p className="error-message-connexion">{codeClientError}</p>}
                        <div className='container-label-input'>
                            <div>
                                <label className="label-input required">Nom de votre pharmacie</label>
                            </div>

                          <div className='input-pharmaName'>
                          <input
                                type="text"
                                className="input"
                                id="pharmacyName"
                                value={nomPharmacie}
                                onChange={(e) => {
                                    setNomPharmacie(e.target.value);
                                    if (e.target.value !== '' && nomPharmacie !== '') {
                                        setCodeClient('');
                                    }
                                }}
                                readOnly 
                                disabled
                            />
                              {isFetchingPharmacy && <img src={loaderGif} className='loaderInput' alt="Chargement" />}
                          </div>
                            
                        </div>
                        <div className='container-label-input'>
                            <label className="label-input ">Nom</label>
                            <input
                                type="text"
                                className="input"
                                id="surname"
                                value={nom}
                                onChange={(e) => setNom(e.target.value)}
                                required
                            />
                        </div>
                        <div className='container-label-input'>
                            <label className="label-input">Prénom</label>
                            <input
                                type="text"
                                className="input"
                                id="name"
                                value={prenom}
                                onChange={(e) => setPrenom(e.target.value)}
                                required
                            />
                        </div>
                        <div className='container-label-input'>
                            <div className='container-inputPass-icon'>
                            <label className="label-input" htmlFor="email">Email</label>
                                <img src={iconInfo} className="icon-information" alt="Information" onClick={toggleModal2} />
                                <Modal isOpen={modalOuvert2} onClose={toggleModal2} firstButton={"Retour"} nbmButton={1}>
                                    <p className='text-modal'>Veuillez utiliser l'adresse email renseignée sur votre compte Glady.</p>
                                </Modal>
                            </div>
                            <input
                                type="email"
                                className="input"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className='container-label-input'>
                            <div className='container-inputPass-icon'>
                                <label className="label-input" htmlFor="password">Créez votre mot de passe</label>
                                <img src={iconInfo} className="icon-information" alt="Information" onClick={toggleModal} />
                                <Modal isOpen={modalOuvert} onClose={toggleModal} firstButton={"Retour"} nbmButton={1}>
                                    <p className='text-modal'>Votre mot de passe doit contenir au minimum : 8 caractères dont 1 majuscule, 1 chiffre, 1 caractère spécial</p>
                                </Modal>
                            </div>
                            <div className='input-pswd'>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <img className='eye-icn' src={showPassword ? eye_on : eye_off } onClick={togglePasswordVisibility} />
                            </div>
                        </div>
                        <ValidPassword password={password} />
                        <div className='container-label-input'>
                            <label className="label-input" htmlFor="password">Confirmez le mot de passe</label>
                            <div className='input-pswd'>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    id="confirmPwd"
                                    value={confirmPassword}
                                    onChange={(e) => setconfirmPassword(e.target.value)}
                                    required
                                />
                                <img className='eye-icn' src={showPassword ? eye_on : eye_off } onClick={togglePasswordVisibility} />
                            </div>
                        </div>
                        {errorMessage && <p className="error-message-connexion">{errorMessage}</p>}
                        <div className='container-btn'>
                            <button className="submit-btn-inscription pointer" type="submit">S'inscrire</button>
                            <p onClick={handleClick} className='text-inscription'>Se connecter</p>
                        </div>
                    </form>
                </div>
            ) }
        </div>
    )
}
export default Inscription;
