import React from 'react';
import '../../utils/css/components/general/productCard.css';

const ProductCard = ( props ) => {

    const pourcentage_width = ( props.qty_sell * 100 ) / props.qty_totale + "%";

    return (
        <div className="container-product-card">
            <div className='intern-container-product-card-1'>
                <p>{props.name}</p>
            </div>
            <div className='intern-container-product-card-2'>
                <img src={props.image} />
            </div>
            <div className='intern-container-product-card-3'>
                <div className='container-progress-bar'>
                    <div className='progress-bar' style={{ width: pourcentage_width }}></div>
                </div>
                <p>{props.qty_sell}/{props.qty_totale}</p>
            </div>
        </div>
    );
};

export default ProductCard;
