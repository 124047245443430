import React, { useState } from 'react';
import '../utils/css/views/formation.css';
import Sidebar from '../components/general/Sidebar';
import Header from '../components/general/Header';

function Formation() {

    return (
        <div className='container-formation'>
            <Sidebar currentPage={"Formations"} />
            <div className='container-all-data-formation'>
                <Header title={"Formation"} bold={"Formation"} />
                <div className='container-content-formation'>
                    <h1 className='title-formation'>Page en construction</h1>
                    <p className='text-formation'>Veuillez patienter, nous concoctons notre meilleur mélange pour vous enseigner au mieux notre savoir</p>
                </div>
            </div>
        </div>
    )
}

export default Formation;