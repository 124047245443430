import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function ResizeRedirect() {
    const navigate = useNavigate();
    const location = useLocation();
    const [lastRoute, setLastRoute] = useState('/'); 

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1020) {
                // Mémoriser la dernière route si nous ne sommes pas déjà sur la page de resize
                if (location.pathname !== '/resize_page') {
                    setLastRoute(location.pathname);
                }
                navigate('/resize_page');
            } else {
                // Si la taille de l'écran augmente au-delà de 1020 px et que nous sommes sur la page de resize
                if (location.pathname === '/resize_page' && lastRoute) {
                    navigate(lastRoute); // Rediriger vers la dernière route mémorisée
                }
            }
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [navigate, lastRoute, location.pathname]);

    return null;
}

export default ResizeRedirect;
