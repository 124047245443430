import React from 'react';
import '../../utils/css/components/general/moreInfo.css';
import visibilityImg from '../../assets/icon/moreInfo.png';
import { useNavigate } from 'react-router-dom';

const MoreInfo = ( props ) => {
  let navigate = useNavigate();

  const handleClick = () => {
    navigate(`${props.path}${props.id}`, {
      state: { 
          name: props.name, 
          limitDate: props.limitDate,
          type: props.type,
          id: props.id_chall
      }
  });
  };

  return (
    <div className="container-moreInfo" onClick={handleClick}>
        <img className='icon-moreInfo' src={visibilityImg} alt="Plus d'informations" />
        <p className='text-moreInfo'>Voir le détail</p>
    </div>
  );
};

export default MoreInfo;
