import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../components/general/Sidebar';
import Header from '../components/general/Header';
import '../utils/css/views/detailsChallengeExceptionnel.css';
import moreInfo from '../assets/icon/moreInfo.png'
import FileHandler from '../components/challenges/globalDoc';
import Timer from '../components/general/Timer';
import CircleProgressBar from '../components/general/CircleProgressBar'
import HistoriqueVente from '../components/challenges/HistoriqueVente';
import Modal from '../components/general/Modal';
import addIcon from '../assets/icon/add_circle.png'
import produitImage from '../assets/img/produits/ACTIVEIN® 60 gél.png'
import challenge_exceptionnel from '../assets/img/challenges/challenge-exceptionnel/challenge_allerfix.jpg'
import ListingProducts from '../components/challenges/ListingProducts';
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../views/GlobalStateContext';
import { useLocation } from 'react-router-dom';
import Loader from '../components/general/LoaderGIF';

function DetailsChallengeExceptionnel() {
    const { urlAPI } = useGlobalState();
    const userToken = localStorage.getItem('userToken');
    let navigate = useNavigate();
    const { id } = useParams();
    const [percentage, setPercentage] = useState(0);
    const [challengeData, setChallengeData] = useState(null);
    const [timer, setTimer] = useState('');
    const [ventesData, setVentesData] = useState(null);
    const [qtySold, setQtySold] = useState(0);
    const [resultQtyToSells, setResultQtyToSells] = useState(0);
    const [modalOuvert, setModalOuvert] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const [fileName, setFileName] = useState('');
    const { name, limitDate, type, id_chall } = location.state || {};

    useEffect(() => {
        const fetchData = async () => {
            try {
                const quantity_objectif = await fetchDetailsChallenge();
                fetchDetailsSells();
                calculResultQtyToSells(quantity_objectif);
            } catch (error) {
                console.error('Erreur lors de la récupération des détails du challenge: ', error);
            }
        };
    
        fetchData();
    }, [qtySold]);
    

    const fetchDetailsSells = async () => {
        const url = `${urlAPI}/challenge/getSellsByChallenge`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${userToken}`,
                },
                body: JSON.stringify({ id_challenge: id }),
            });
            if (!response.ok) {
                throw new Error('Problème lors de la récupération des données de l\'utilisateur');
            }
            const data = await response.json();
            setVentesData(data)
            let qtyToSell_temp = 0;
            data.forEach(element => {
                qtyToSell_temp += element.sommeQuantity;
            });
            setQtySold(qtyToSell_temp);
            setIsLoading(false);
        } catch (error) {
            console.error('Erreur lors de la récupération des détails de l’utilisateur:', error);
        }
    }

    const fetchDetailsChallenge = async () => {
        const url = `${urlAPI}/challenge/getChallengeByID/${id}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${userToken}`,
                }
            });
            if (!response.ok) {
                throw new Error('Problème lors de la récupération des données du challenge');
            }
            const data = await response.json();
         
            setChallengeData(data);
            setIsLoading(false);
            return data.quantity_objectif
        } catch (error) {
            console.error('Erreur lors de la récupération des détails du challenge: ', error);
        }
    }

    const calculResultQtyToSells = async () => {
        let resultQtyToSells = 0;
        if (challengeData !== null) {
            if (qtySold == 0) {
                resultQtyToSells = challengeData.quantity_objectif;
            } else {
                resultQtyToSells = challengeData.quantity_objectif - qtySold;
                if (resultQtyToSells < 0) {
                    resultQtyToSells = 0;
                }
            }
            setResultQtyToSells(resultQtyToSells);
            
            let percentage_temp = (qtySold * 100) / challengeData.quantity_objectif;
            percentage_temp = Math.round(percentage_temp);
            setPercentage(percentage_temp);
        }
    }
    

    const productsData = [
        { 'name': 'ACTIVEIN® 60 gél.png', 'image': produitImage, 'qty_sell': 80, 'qty_totale': 100 },
        { 'name': 'ACTIVEIN® 60 gél.png', 'image': produitImage, 'qty_sell': 20, 'qty_totale': 100 },
        { 'name': 'ACTIVEIN® 60 gél.png', 'image': produitImage, 'qty_sell': 100, 'qty_totale': 100 },
        { 'name': 'ACTIVEIN® 60 gél.png', 'image': produitImage, 'qty_sell': 80, 'qty_totale': 100 },
        { 'name': 'ACTIVEIN® 60 gél.png', 'image': produitImage, 'qty_sell': 20, 'qty_totale': 100 },
        { 'name': 'ACTIVEIN® 60 gél.png', 'image': produitImage, 'qty_sell': 100, 'qty_totale': 100 },
        { 'name': 'ACTIVEIN® 60 gél.png', 'image': produitImage, 'qty_sell': 80, 'qty_totale': 100 },
        { 'name': 'ACTIVEIN® 60 gél.png', 'image': produitImage, 'qty_sell': 20, 'qty_totale': 100 },
        { 'name': 'ACTIVEIN® 60 gél.png', 'image': produitImage, 'qty_sell': 100, 'qty_totale': 100 },
    ]

    const toggleModal = () => {
        setModalOuvert(!modalOuvert);
    };

    const handleProducts = () => {
        setModalOuvert(true);
    };

    const handleClicDetails = (id) => {
        navigate(`/challenges/challenge_exceptionnel_details/ventes/${id}`, {
            state: {
                name: challengeData.name,
                limitDate: challengeData.limit_date,
                type: challengeData.type_challenge,
                id: challengeData.id,
                dateEnd: challengeData.end_date
            }
        });
    };    

    const handleFileAdded = (file) => {
        setFileName(file.name);
    };

    return (
        <div className='container-challenges'>
            <Sidebar currentPage={"Challenges"} />
            <div className='container-all-data-challenges'>
                <Header title={name} back={true} />
                <div className='container-without-header'>
                    <div className='big-container-details-challenge-exceptionnel'>
                        <div className='container-details-challenge-exceptionnel-part-one'>
                            <h2 className='subtitle-challenges'>Mon objectif</h2>
                            <div className='details-challenge-exceptionnel-container-objectif'>
                                <div className='details-challenge-exceptionnel-sub-container-objectif-one'>
                                    <div className='details-challenge-exceptionnel-mini-container'>
                                        { isLoading === true ? (
                                            <Loader />
                                        ) : (
                                            <p id='title-objectif-container'>Vendez {challengeData.quantity_objectif} unités</p>
                                        )}
                                        {/* <div className='container-img-moreInfo-objectif' onClick={() => { handleProducts() }}>
                                            <img className='img-moreInfo-objectif' src={moreInfo} />
                                            <p>Voir les produits</p>
                                        </div> */}
                                    </div>
                                </div>
                                <div className='details-challenge-exceptionnel-sub-container-objectif-two'>
                                    { isLoading === true ? (
                                        <Loader />
                                    ) : (
                                        <Timer timer={timer} setTimer={setTimer} challengeData={challengeData.end_date} variante={1} />
                                    )}
                                </div>
                            </div>
                            { isLoading === true ? (
                                <Loader />
                            ) : (
                            <>
                                <h2 className='subtitle-challenges'>Ma progression</h2>
                                <div className='container-progression'>
                                    <CircleProgressBar percentage={percentage >= 100 ? 100 : percentage} circleWidth="110" />
                                    <p className='text-progression'>
                                        {qtySold >= challengeData.quantity_objectif ? "Vous avez atteint l'objectif !" : `Plus que ${resultQtyToSells === 0 ? challengeData.quantity_objectif : resultQtyToSells} unités à vendre`}
                                    </p>
                                </div>
                            </>
                            )}
                            
                        </div>
                        <div className='container-details-challenge-exceptionnel-part-two'>
                            <img src={challenge_exceptionnel} className='img-challenge-exceptionnel'/>
                        </div>
                    </div>
                    <div className='bandeau-ventes-container'>
                        <h1 className='bandeau-ventes-title'>Mes ventes</h1>
                        <div className='container-add-file'>
                            {/* <FileHandler
                                initialFileName={fileName} 
                                onFileAdded={handleFileAdded}
                                isFinished={timer}
                                /> */}
                            { timer != "FINISHED" && (
                            <div className='button-ventes-add-exceptionnel' onClick={() => handleClicDetails(challengeData.id)} >
                                <img src={addIcon} alt="Add icon" />
                                <p>J'ajoute mes ventes</p>
                            </div>
                            )}
                        </div>
                    </div>
                    {ventesData && <HistoriqueVente ventes={ventesData} type_challenge={challengeData['type_challenge']} timer={timer} challenge={challengeData} />}
                    {modalOuvert && (
                        <Modal isOpen={modalOuvert} onClose={toggleModal} width={0}>
                            <div className='container-header-list-product'>
                                <h2 className='subtitle-challenges'>Listes des produits</h2>
                                <button className='button-modal-second' onClick={toggleModal}>X</button>
                            </div>
                            <div className='container-list-product'>
                                <ListingProducts data={productsData} />
                            </div>
                        </Modal>
                    )}
                </div>
            </div>
        </div>
    )
}

export default DetailsChallengeExceptionnel;