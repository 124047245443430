import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/general/Sidebar';
import Header from '../components/general/Header';
import '../utils/css/views/challengeStatistques.css';
import BarChart from '../components/challenges/BarChartStats';
import ChalStats from '../components/challenges/chalStats';
import HistoriqueList from '../components/challenges/HistoriqueList';
import { useLocation } from 'react-router-dom';


function ChallengeStatistiques() {
  const location = useLocation();
  const { chartData, uvc, gain, ca_trim, dataCurrent } = location.state || {};

  return (
      <div className='container-challenges'>
          <Sidebar currentPage={"Challenges"} />
          <div className='container-all-data-challenges'>
              <Header title={"Mes "} bold={"statistiques"} back={true} />
              <div className='container-without-header'>
                  <div className='container-bloc-contenue'>
                      <h2 className='subtitle-challenges'>Mes dernières statistiques</h2>
                      <div className='container-bloc-statistiques'>
                      <div className='container-chart-statistiques'> 
                          <ChalStats chartData={chartData} gain={gain} uvc={uvc} ca_trim={ca_trim} /> {/* Utilisation de ChalStats */}
                      </div>
                    </div>
                      <h2 className='subtitle-challenges-historique'>Historique</h2>
                      <HistoriqueList />
                  </div>
              </div>
          </div>
      </div>
  );
}

export default ChallengeStatistiques;