import React, { useState, useEffect } from 'react';

function Timer({ challengeData, variante, setTimer, timer }) {

    useEffect(() => {
        const interval = setInterval(() => {
            const timeRemaining = calculateTimeRemaining(challengeData);
            setTimer(formatTime(timeRemaining));
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [challengeData]); 

    const calculateTimeRemaining = (endDate) => {
        const currentTime = new Date().getTime();
        const endTime = new Date(endDate).getTime();
        const timeRemaining = endTime - currentTime;
        return timeRemaining > 0 ? timeRemaining : 0;
    };

    const formatTime = (time) => {
        if (time <= 0) {
            return "FINISHED";
        }

        const weeks = Math.floor(time / (1000 * 60 * 60 * 24 * 7));
        const days = Math.floor((time % (1000 * 60 * 60 * 24 * 7)) / (1000 * 60 * 60 * 24));
        const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((time % (1000 * 60)) / 1000);
        return [weeks, days, hours, minutes, seconds];
    };

    return (
        <div className={`container-timer-details-challenge-trimestriel-${variante === 0 ? '0' : variante === 1 ? '1' : ''}`}>
            {timer !== "FINISHED" ? (
                <div className={`timer-details-challenge-trimestriel-big-container-${variante === 0 ? '0' : variante === 1 ? '1' : ''}`}>
                    <div className={`timer-details-challenge-trimestriel-container-${variante === 0 ? '0' : variante === 1 ? '1' : ''}`}>
                        <p className={`timer-details-challenge-trimestriel-value-${variante === 0 ? '0' : variante === 1 ? '1' : ''}`}>{timer[0]}</p>
                        <p className={`timer-details-challenge-trimestriel-text-${variante === 0 ? '0' : variante === 1 ? '1' : ''}`}>Semaines</p>
                    </div>
                    <p className={`timer-details-challenge-trimestriel-separator-${variante === 0 ? '0' : variante === 1 ? '1' : ''}`}>:</p>
                    <div className={`timer-details-challenge-trimestriel-container-${variante === 0 ? '0' : variante === 1 ? '1' : ''}`}>
                        <p className={`timer-details-challenge-trimestriel-value-${variante === 0 ? '0' : variante === 1 ? '1' : ''}`}>{timer[1]}</p>
                        <p className={`timer-details-challenge-trimestriel-text-${variante === 0 ? '0' : variante === 1 ? '1' : ''}`}>Jours</p>
                    </div>
                    <p className={`timer-details-challenge-trimestriel-separator-${variante === 0 ? '0' : variante === 1 ? '1' : ''}`}>:</p>
                    <div className={`timer-details-challenge-trimestriel-container-${variante === 0 ? '0' : variante === 1 ? '1' : ''}`}>
                        <p className={`timer-details-challenge-trimestriel-value-${variante === 0 ? '0' : variante === 1 ? '1' : ''}`}>{timer[2]}</p>
                        <p className={`timer-details-challenge-trimestriel-text-${variante === 0 ? '0' : variante === 1 ? '1' : ''}`}>Heures</p>
                    </div>
                    {variante === 0 && (
                        <>
                            <p className={`timer-details-challenge-trimestriel-separator-${variante === 0 ? '0' : variante === 1 ? '1' : ''}`}>:</p>
                            <div className={`timer-details-challenge-trimestriel-container-${variante === 0 ? '0' : variante === 1 ? '1' : ''}`}>
                                <p className={`timer-details-challenge-trimestriel-value-${variante === 0 ? '0' : variante === 1 ? '1' : ''}`}>{timer[3]}</p>
                                <p className={`timer-details-challenge-trimestriel-text-${variante === 0 ? '0' : variante === 1 ? '1' : ''}`}>Minutes</p>
                            </div>
                            <p className={`timer-details-challenge-trimestriel-separator-${variante === 0 ? '0' : variante === 1 ? '1' : ''}`}>:</p>
                            <div className={`timer-details-challenge-trimestriel-container-${variante === 0 ? '0' : variante === 1 ? '1' : ''}`}>
                                <p className={`timer-details-challenge-trimestriel-value-${variante === 0 ? '0' : variante === 1 ? '1' : ''}`}>{timer[4]}</p>
                                <p className={`timer-details-challenge-trimestriel-text-${variante === 0 ? '0' : variante === 1 ? '1' : ''}`}>Secondes</p>
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <h1 className={`timer-finished-details-challenge-trimestriel-text-${variante === 0 ? '0' : variante === 1 ? '1' : ''}`}>CHALLENGE TERMINÉ le {challengeData}</h1>
            )}
        </div>
    );
}

export default Timer;
