import React, { useState } from 'react';
import '../../utils/css/components/general/listingElement.css';
import iconAddBeneficiaire from '../../assets/icon/add_circle_black.png';
import iconModif from '../../assets/icon/border_color.png';
import iconDelete from '../../assets/icon/delete.png';
import Modal from '../../components/general/Modal';
import { useGlobalState } from '../../views/GlobalStateContext';

const ListingElement = ({ columns, data, searchTerm, searchColumn, isBeneficiary }) => {
    const [emailInvit, setEmailInvit] = useState();
    const [showDelete, setShowDelete] = useState(false);
    const [modalOuvert, setModalOuvert] = useState(false);
    const [modalUpdateOuvert, setModalUpdateOuvert] = useState(false);
    const [modalCreateOuvert, setModalCreateOuvert] = useState(false);
    const [deleteSelected, setDeleteSelected] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const { urlAPI } = useGlobalState();

    const userToken = localStorage.getItem('userToken');

    const toggleDeleteText = () => {
        if (!isBeneficiary) {
            setShowDelete(!showDelete);
        }
    };

    const toggleModal = () => {
        if (!isBeneficiary) {
            setModalOuvert(!modalOuvert);
        }
    };

    const toggleModalDelete = () => {
        if (!isBeneficiary) {
            setModalOuvert(!modalOuvert);

            fetch(urlAPI + "/organization/deleteCollaborator", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${userToken}`,
                },
                body: JSON.stringify({
                    email: deleteSelected
                }),
            })
            .then(response => response.json())
            .then(data => {
                window.location.reload()
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        }
    }

    const toggleUpdateModal = () => {
        if (!isBeneficiary) {
            setModalUpdateOuvert(!modalUpdateOuvert);
        }
    };

    const toggleCreateModal = () => {
        if (!isBeneficiary) {
            setModalCreateOuvert(!modalCreateOuvert);
        }
    };

    const toggleCreateModalInvit = () => {
        if (!isBeneficiary) {

            fetch(urlAPI + "/organization/inviteCollaborator", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${userToken}`,
                },
                body: JSON.stringify({
                    email: emailInvit
                }),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.message == "Envoie du mail réussi.") {
                        setModalCreateOuvert(!modalCreateOuvert);
                        window.location.reload()
                    } else {
                        setErrorMessage(data.message)
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }

    const handleModif = (index, email) => {
        setDeleteSelected(email)
        if (!isBeneficiary) {
            setModalOuvert(true);
        }
    };    

    const handleCreate = () => {
        if (!isBeneficiary) {
            setModalCreateOuvert(true);
        }
    };

    return (
        <div>
            <table>
                <thead>
                    <tr>
                        {columns.map((column) => (
                            <th className='title-listingElement' key={column}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data
                        .filter((item) =>
                            item[searchColumn].toLowerCase().includes(searchTerm.toLowerCase())
                        )
                        .map((item, index) => (
                            <tr key={index}>
                                {columns.map((column) => (
                                    <td
                                        className='item-listingElement'
                                        key={column}>
                                        {item[column]}
                                    </td>
                                ))}
                                {showDelete && <div onClick={() => handleModif(index, item['Adresse e-mail'])} className='container-delete-box'>
                                    <img src={iconDelete} alt="Supprimer" />
                                    <td className='delete-text'>Supprimer</td>
                                </div>}
                            </tr>
                        ))
                    }

                </tbody>
            </table>
            <div className='container-params-beneficiaire'>
                <div className={isBeneficiary ? 'container-icon-value-beneficiaire hidden' : 'container-icon-value-beneficiaire'} onClick={toggleDeleteText}>
                    <img className='icon-update' src={iconModif} alt="Modifier" />
                    <label>Supprimer</label>
                </div>
                <div className={isBeneficiary ? 'container-icon-value-beneficiaire hidden' : 'container-icon-value-beneficiaire'} onClick={handleCreate}>
                    <img className='icon-add-beneficiaire' src={iconAddBeneficiaire} alt="Ajouter un bénéficiaire" />
                    <label>Ajouter un bénéficiaire</label>
                </div>
            </div>
            {modalOuvert && (
                <Modal isOpen={modalOuvert} onClose={toggleModal}>
                    <p className='text-modal'>Vous êtes sur le point de supprimer ce bénéficiaire.</p>
                    <div className='container-button-modal'>
                        <button className='button-modal-second' onClick={toggleModal}>Annuler</button>
                        <button className='button-modal' onClick={toggleModalDelete}>Supprimer</button>
                    </div>
                </Modal>
            )}
            {modalUpdateOuvert && (
                <Modal isOpen={modalUpdateOuvert} onClose={toggleUpdateModal}>
                    <p className='text-modal'>Modifier les informations de votre bénéficiaire.</p>
                    <div>
                        <div className="form-group">
                            <label className='text-label-input'>Nom</label>
                            <input className='input'></input>
                        </div>
                        <div className="form-group">
                            <label className='text-label-input'>Adresse e-mail</label>
                            <input className='input'></input>
                        </div>
                        <div className='container-button-modal'>
                            <button className='button-modal-second' onClick={toggleUpdateModal}>Annuler</button>
                            <button className='button-modal' onClick={toggleUpdateModal}>Valider</button>
                        </div>
                    </div>
                </Modal>
            )}
            {modalCreateOuvert && (
                <Modal isOpen={modalCreateOuvert} onClose={toggleCreateModal}>
                    <p className='text-modal'>Inviter un nouveau bénéficiaire !</p>
                    <div>
                        <label className='text-label-input'>Adresse e-mail</label>
                        <input
                            type="text"
                            className="input"
                            id="email"
                            onChange={(e) => setEmailInvit(e.target.value)}
                            required
                        />
                    </div>
                    <div className='container-button-modal'>
                        <button className='button-modal-second' onClick={toggleCreateModal}>Retour</button>
                        <button className='button-modal' onClick={toggleCreateModalInvit}>Inviter</button>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default ListingElement;
