import React, { useState } from 'react';
import '../utils/css/views/errorPage.css';
import logo from '../assets/img/logo-prescription-nature-HD 1.png';
import imgError from '../assets/img/pageError.jpeg';
import { useNavigate } from 'react-router-dom';

function ErrorPage() {

    const navigate = useNavigate();

    const handleMenuClick = (path) => {
        navigate(path);
    };

    return (
        <div className='container-errorPage'>
            <img src={logo}/>
            <h1 className='title-errorPage'>Oups il semblerait <br/> qu'il y ai un problème</h1>
            <p className='text-errorPage'>Nous essayons de trouver une solution</p>
            <div className='btn-errorPage' onClick={() => handleMenuClick('/')}>
                <p className='text-btn-errorPage pointer'>Retourner à la page de connexion</p>
            </div>
            <img className='img-other-errorPage' src={imgError}/>
        </div>
    )
}

export default ErrorPage;