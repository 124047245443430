import React from 'react';
import ReactDOM from 'react-dom';
import '../../utils/css/components/general/modal.css';

const Modal = ({ children, isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>,
    document.body // Ici, nous spécifions que la modal doit être rendue directement dans l'élément <body>
  );
};

export default Modal;
