import React, { useState, useEffect } from 'react';
import '../../utils/css/components/auth/validPassword.css'

function ValidPassword(props) {
    const regexMajuscules = /[A-Z]/;
    const regexCaracteresSpeciaux = /[!@#$%^&*(),.?":{}|<>]/;
    const regexChiffres = /\d/;
    const longueurMinimale = 8;

    const [validationResults, setValidationResults] = useState([false, false, false, false]);

    useEffect(() => {
        const valideChiffres = regexChiffres.test(props.password);
        const valideCaracteresSpeciaux = regexCaracteresSpeciaux.test(props.password);
        const valideMajuscules = regexMajuscules.test(props.password);
        const valideTaille = props.password.length >= longueurMinimale;

        setValidationResults([valideChiffres, valideCaracteresSpeciaux, valideMajuscules, valideTaille]);
    }, [props.password]); // Cet effet s'exécutera à chaque changement de password

    const nombreElementsValides = validationResults.filter(Boolean).length;

    return (
        <div className='container-validPassword' style={props.style}>
            <div className={`validPassword-step ${nombreElementsValides >= 1 ? 'step-1' : ''}`}></div>
            <div className={`validPassword-step ${nombreElementsValides >= 2 ? 'step-2' : ''}`}></div>
            <div className={`validPassword-step ${nombreElementsValides >= 3 ? 'step-3' : ''}`}></div>
            <div className={`validPassword-step ${nombreElementsValides === 4 ? 'step-4' : ''}`}></div>
        </div>

    )
}

export default ValidPassword;