import React, { useState } from 'react';
import '../utils/css/views/resizePage.css';
import logo from '../assets/img/logo-prescription-nature-HD 1.png';
import imgSize from '../assets/img/ecran_petit.png';

function ResizePage() {

    return (
        <div className='container-sizeMode'>
            <img src={logo}/>
            <img className='img-size' src={imgSize}/>
            <h1>Votre écran est trop petit</h1>
            <p className='text-sizeMode'>Oups, il semblerait que vous tentiez d’accéder à notre site avec un smartphone ou une tablette.
                <br/><br/>Connectez vous sur un ordinateur afin de visualiser cette page</p>
        </div>
    )
}

export default ResizePage;