import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import '../../utils/css/components/general/graph.css';

const Graph = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (data.length === 0) return;

    const dates = data.map(entry => entry.date);
    const gains = data.map(entry => entry.gain);

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');

    chartInstance.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: dates,
        datasets: [{
          data: gains,
          borderColor: '#7CB4A8',
          tension: 0.1
        }]
      },
      options: {
        plugins: {
          legend: {
            display: false 
          }
        },
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });

  }, [data]);

  return (
    <div className="container-graph">
      <canvas ref={chartRef} />
    </div>
  );
};

export default Graph;
