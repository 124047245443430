import React, { useState } from 'react';
import '../utils/css/views/pageInconnu.css';
import logo from '../assets/img/logo-prescription-nature-HD 1.png';
import imgInconnu from '../assets/img/page-inconnu.png';
import { useNavigate } from 'react-router-dom';

function PageInconnu() {

    const navigate = useNavigate();

    const handleMenuClick = (path) => {
        navigate(path);
    };

    return (
        <div className='container-inconuPage'>
            <div className='container-content-inconuPage'>
                <img className='img-logo-inconuPage' src={logo} />
                <h1 className='title-inconuPage'>Oups cette page <br /> est introuvable</h1>
                <div className='btn-inconuPage' onClick={() => handleMenuClick('/tableau_de_bord')}>
                    <p className='text-inconuPage'>Retourner à l'accueil</p>
                </div>
            </div>
            <img className='img-inconnuPage' src={imgInconnu}/>
        </div>
    )
}

export default PageInconnu;